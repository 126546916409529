import React from 'react'

import TeamCard from 'components/TeamCard'
import { SubHeading } from 'components/Headings'
import { JustAppear, SlideBottom } from 'components/SlideAnimation'
import { Markdown } from 'react-showdown'

export default function ExecutiveTeam(props) {
    
    const { aboutPage } = props
    

    return (<>
        <div id="executive-team" className='pt-[60px] lg:pt-[70px] pb-[70px] container mx-auto'>
            <SubHeading className="inter text-center ">
                <SlideBottom>
                {aboutPage?.executiveTeam?.heading}
                </SlideBottom>
            </SubHeading>
            <JustAppear>
            <TeamCard teamList={aboutPage?.executiveTeam?.teamList} loop={false} />
            </JustAppear>
        </div>
        {
        (aboutPage?.team?.heading || aboutPage?.team?.description || aboutPage?.team?.teamList.length > 0 ) &&
            <div id="executive-team" className='pt-[10px] lg:pt-[10px] pb-[70px] container mx-auto'>
                {aboutPage?.team?.heading &&
                <SubHeading className="inter text-center ">
                    <SlideBottom>
                        {aboutPage?.team?.heading}
                    </SlideBottom>
                </SubHeading>
                }
                {
                    aboutPage?.team?.description &&
                    <div className="pt-[20px]">
                        <SlideBottom>
                            <Markdown markup={aboutPage?.team?.description} />
                        </SlideBottom>
                    </div>
                }
                {
                    aboutPage?.team?.teamList.length > 0 &&
                    <JustAppear>
                        <TeamCard teamList={aboutPage?.team?.teamList} />
                    </JustAppear>
                }
            </div>
        }

    </>
    )
}
