import React from 'react'
import { Link } from 'react-router-dom'
import MarkdownView from 'react-showdown';

// import {ReactComponent as Twitter}  from 'assets/logo/twitter.svg'
import Twitter from 'assets/logo/twitter.svg'
import YouTube from 'assets/logo/youtube.svg'
import FB from 'assets/logo/fb.svg'
import LinkedIn from 'assets/logo/LinkedIn.svg'
import Instagram from 'assets/logo/instgram.png'
// import Exit from 'assets/logo/exit.svg'
import Friday from 'assets/logo/Friday.png'


import {footer } from './data'
import { getImage } from 'components/getImage'
import useStoreData from 'store/storeData'


export default function Index(props) {

    const {storeData} = useStoreData()
    const footerData = storeData?.footerData || {}
    const navBarLogo = storeData?.navBar || {}

    const logoURL = getImage(navBarLogo?.logo_white?.url, "/assets/logo/logo.svg")

    const _style = {
        mainBg: {
            background: `url(), #27386C`,
            // backgroundSize : 'cover'
        }
    }

    return (<div>
        <div className='py-[30px] lg:py-[44px] bg-blue-footer  '>
            <div className="container mx-auto">

                <div className="flex  flex-wrap lg:flex-nowrap">
                    <div className="logo order-1 lg:order-1 lg:w-[190px]">
                        <img src={logoURL} alt=""  className=''/>
                    </div>
                    <div className="links w-full   lg:w-fit lg:w-[160px] 2xl:w-[240px] pt-[8px] lg:pt-[0px] lg:pt-0 order-3 lg:order-2 ">
                        <div className="flex flex-wrap  lg:block ">

                            {
                                footer?.links?.map(item => {
                                    return (<div key={item?.label} className="w-1/2 lg:w-full pb-3 text-white">
                                        <Link to={item.url} className='inter text-white text-[16px] font-[400]'>
                                            {item.label}
                                        </Link>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    <div className='order-2 address_block w-full lg:w-[450px] 2xl:w-[500px] text-white pt-[30px] lg:pt-0 lg:order-3'>
                        <div className="mb-3 flex text-[14px]">
                            Address : &nbsp; <span className='font-[700]'> <MarkdownView markdown={footerData?.address} /> </span>
                        </div>
                        {!!footerData?.phone &&
                        <div className="mb-3 flex text-[14px]">
                            Phone : &nbsp;
                            <a href={`tel:${footerData?.phone}`} className="font-[700] text-white" >
                                <MarkdownView markdown={footerData?.phone} />
                            </a>
                        </div>
                        }
                        {!!footerData?.email &&
                        <div className="mb-3 flex text-[14px]">
                            Email : &nbsp;
                            <a href={`mailto:${footerData?.email}`} className="font-[700] text-white"  >
                                <MarkdownView markdown={footerData?.email} />
                            </a>
                        </div>
                        }
                    </div>
                    <div className="w-full lg:w-[210px] text-white pt-[10px]   lg:pt-0 order-4 lg:order-4">
                        <div className="flex flex-wrap items-start">
                        <div className='grow lg:w-full pb-3'>

                            <div className="text-[14px] pb-3 font-[600]">
                                Find us on social media:
                            </div>
                            <div className="flex">
                                {
                                    footerData?.social?.twitter && <div> <a target='_blank' href={footerData?.social?.twitter}> <img className='mr-[15px]' src={Twitter} alt="" /> </a> </div>
                                }
                                {
                                    footerData?.social?.youtube && <div> <a target='_blank' href={footerData?.social?.youtube}> <img className='mr-[15px]' src={YouTube} alt="" /> </a> </div>
                                }
                                {
                                    footerData?.social?.facebook && <div> <a target='_blank' href={footerData?.social?.facebook}> <img className='mr-[15px]' src={FB} alt="" /> </a> </div>
                                }
                                {
                                    footerData?.social?.linkedin && <div> <a target='_blank' href={footerData?.social?.linkedin}> <img className='mr-[15px]' src={LinkedIn} alt="" /> </a> </div>
                                }
                                {
                                    footerData?.social?.instagram && <div> <a target='_blank' href={footerData?.social?.instagram}> <img className='mr-[15px] w-[30px] ' src={Instagram} alt="" /> </a> </div>
                                }
                            </div>
                        </div>

                        <div className='w-fit lg:w-full'>
                            <div className="pb-3 text-[14px]">
                                Developed by
                            </div>
                            <div className="exit max-w-[80px]">
                                <a href="http://fridaydd.com/" target="_blank" >
                                <img src={Friday} alt="exit studios" />
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className='text-center py-[12px] bg-[#3551BD] text-white text-[14px]'>
            <MarkdownView markup={footerData?.copyright} />
        </div>
    </div>
    )
}
