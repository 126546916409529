import React, {useState} from 'react'
import MarkdownView from 'react-showdown';

import useMobile from 'components/useMobile'
import { SecondaryCard } from 'components/Cards'
import Primary from 'components/Buttons/Primary';
import { SlideLeft, SlideTop } from 'components/SlideAnimation';


// Form
import * as yup from 'yup';
import axios from 'axios'

export default function MailList(props) {
    
    const {homePage} = props

    const { isMobile } = useMobile()
    const initialValues = {
        email : ''
    }

    const [value, setValue] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const [disableMail, setDisableMail] = useState(false)

    const Schema = yup.object({
        email: yup.string().required("Email is a required field").email("Invalid email"),
    });


    const handeChange = (e) => {
        setError("")
        if(loading || disableMail){
            return
        }
        setValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const onSuccessClose = () => {
        setSuccess(false)
        setValue(initialValues)
       
    }

    const onSubmit = async() =>{
        setError("")
        if(loading || disableMail){
            return
        }
        setLoading(true)
        try{
            await Schema.validate({...value})
            const res = await axios.post(`${process.env.REACT_APP_URL}/api/email-carrier/join-mailing-list`, value)
            console.log(res)
            setLoading(false)
            setSuccess(true)
            setDisableMail(true)

        }catch(ex){
            setError(ex?.errors || ex?.response?.data?.error?.message ||"Something went wrong")
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <div className='mx-auto container'>
            <SecondaryCard className="container mx-auto bg-blue-mailList rounded-xl p-[20px] pt-[50px] pb-[60px] md:p-[50px] md:pb-[60px] md:px-[70px]">
                <div className="flex flex-wrap md:flex-nowrap text-white items-end text-left">
                    <div className="w-full md:w-[700px] 3xl:w-[800px]">
                        <div className="text-[24px] 3xl:text-[30px] font-[700]">
                            <SlideTop>
                            <MarkdownView markup={homePage?.MailList?.heading} />
                            </SlideTop>
                            
                        </div>
                        <div className="text-[16px] 3xl:text-[18px] inter leading-[24px] font-[500] mt-[8px] max-w-[318px] 3xl:max-w-[350px]">
                            <SlideLeft>
                            <MarkdownView markup={homePage?.MailList?.description} />
                            </SlideLeft>
                        </div>
                    </div>
                    <div className="w-full md:grow">
                        {isMobile ? 
                            <div className="relative w-full md:w-fit mt-[24px] md:mt-0">
                                 <input 
                                    className={`${error && 'ring-2 ring-red-500'}    w-full  md:w-[300px]  lg:w-[400px]  h-[50px]   rounded-xl text-black text-[14px] p-[20px]`}
                                    placeholder='Enter your mail'
                                    value={value?.email}
                                    name="email"
                                    onChange={handeChange}
                                    />
                                <Primary 
                                    onClick={() => onSubmit()}
                                    label={loading ? "sending..." : "Subscribe" } 
                                    color={`${disableMail ? "bg-[#CBCFD1]" :" bg-primary"}`}
                                    className={` w-full mt-[13px]  h-[52px]  ${loading && 'animate-'}  `} />
                            </div> 
                        : 
                            <div className="relative w-full md:w-fit mt-[24px] md:mt-0 ">
                                <div 
                                    onClick={() => onSubmit()}
                                    className={` ${loading && 'animate-'} cursor-pointer absolute w-[160px] h-[50px] ${disableMail ? "bg-[#CBCFD1]" :" bg-primary"} rounded-xl right-0 text-center font-bold flex items-center justify-center `}> 
                                     {loading ? "sending..." : "Subscribe" } 
                                </div>
                                <input 
                                    onChange={handeChange}
                                    value={value?.email}
                                    name="email"
                                    className={`${error && 'ring-2 ring-red-500'}  ${loading && 'animate-'} w-full  md:w-[350px]   h-[50px]  max-w-[500px] 3xl:max-w-[700px] 3xl:w-[500px] rounded-xl text-black text-[12px] p-[20px]`}
                                    placeholder='Enter your mail'
                                    // disabled={loading}
                                    />
                            </div>
                        }
                    </div>
                </div>
            </SecondaryCard>
            {
                success && <div className='fixed z-50 w-screen h-screen bg-black/50 top-0 left-0 flex flex-col items-center justify-center'>
                    <div className="bg-white rounded-2xl p-[20px] text-center min-w-[300px] ">
                        <div className="text-[18px] font-bold ">Successfully submitted</div>
                        {/* <div className="text-[14px] pt-[10px]">
                        We’ll contact you soon!
                        </div> */}
                        <Primary
                            onClick={onSuccessClose}
                            color="bg-[#1D337A]"
                            label="OK"
                            className="inter text-[20px] font-[700] md:max-w-[100px] mx-auto mt-[20px] " />
                    </div>
                </div>
            }
            
        </div>
    )
}
