import React from 'react'
import { useNavigate } from 'react-router-dom';
import MarkdownView from 'react-showdown';

// components
import ButtonPrimary from 'components/Buttons/Primary'
import ButtonSecondary from 'components/Buttons/Secondary'
import { SlideLeft, SlideRight, SlideBottom} from 'components/SlideAnimation'

export default function Content(props) {
    
    const {homePage} = props
    const navigate = useNavigate()


    

    return (
        <div>
            <div className="relative">
                <div className="absolute  sm:top-[230px] top-[145px] text-white">
                    <div className="font-bold text-[32px] leading-[40px]    lg:text-[54px] lg:leading-[64px]">
                        <SlideRight>
                            <MarkdownView markdown={homePage?.hero_heading} />
                        </SlideRight>
                    </div>
                    <div className="inter font-[400] text-[18px] leading-[28px]  pt-[25px] lg:pt-[20px] leading-[24px] lg:text-[24px] lg:leading-[34px]">
                    <SlideLeft>
                        <MarkdownView markdown={homePage?.hero_description} className="max-w-[580px]" />
                    </SlideLeft>
                    </div>
                    <SlideBottom>
                    <div className=" pt-[25px]  lg:pt-[20px] flex flex-wrap">
                        <ButtonPrimary  onClick={() => navigate("/for-investors")}  label="For Investors" className="mr-[16px] mt-[12px]  text-[18px] lg:text-[20px] w-full  h-[46px] md:h-[60px] md:w-[272px] xl:mr-[12px]  rounded-[8px] lg:rounded-[12px" />
                        <ButtonSecondary onClick={() => navigate("/for-startups")} label="For Startups" className="mr-[16px]  mt-[12px] text-[18px] lg:text-[20px] w-full  h-[46px] md:h-[60px]  md:w-[246px]  rounded-[8px] lg:rounded-[12px" />
                    </div>
                    </SlideBottom>
                </div>
            </div>
        </div>
    )
}
