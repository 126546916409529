export const navbar = {
    logoURL : '/assets/logo/logo.svg',
    logoBlueURL : '/assets/logo/logoBlue.svg',
    menuList : [
        {
            id : 1,
            order: 0,
            title: "About",
            url : "/about",
            
        },
        {
            id : 2,
            order: 2,
            title: "For Investors",
            url : "/for-investors",
            
        },
        {
            id : 3,
            order: 3,
            title: "For Startups",
            url : "/for-startups",
            
        },
        {
            id : 4,
            order: 4,
            title: "The Factory",
            url : "/the-factory",
            children : [
                {
                    id: 1,
                    order: 3,
                    title: "Factory",
                    url: "/the-factory",
                },
                {
                    id: 2,
                    order: 3,
                    title: "Current\u00A0startup\u00A0programs",
                    url: "/the-factory#projects",
                },
                {
                    id: 3,
                    order: 4,
                    title: "Past\u00A0startup\u00A0programs",
                    url: "/the-factory#pastprojects",
                }

            ]
            
        },
        {
            id : 5,
            order: 5,
            title: "Events",
            url : "/events",
            
        },
        {
            id : 6,
            order: 6,
            title: "News",
            url : "/news",
            
        },
    ]
}