import React, { useEffect, useState } from 'react'
import CountUp, { useCountUp } from 'react-countup';
import { useInView } from "react-intersection-observer";

import { motion } from "framer-motion";
import { SlideLeft, SlideRight, SlideBottom, JustAppear } from 'components/SlideAnimation'


export default function Numbers(props) {

    const { homePage } = props

    const [ref, inView] = useInView();
    // const [once, setOnce] = useState(true)
    // const {start} = useCountUp({
    //     startOnMount : false,
    // })

    // useEffect(() => {
    //     if(inView && once ){
    //         // start()
    //         setOnce(false)
    //     }
    // }, [])

    const Box = ({ item, index }) => {
        return (
            <div className={`box pt-[0px] md:pt-0 ${index === 1 && "mx-[25px] lg:mx-0"} ${index === 3 && "w-full mt-[10px] sm:w-fit sm:mt-0"}`}>
                <div className=" text-primary2">
                    <div className='inter text-center'>
                        <div className="font-bold ">
                            <div className="">
                                <span className=' text-[32px]  lg:text-[48px]'>
                                    <CountUp end={item?.value} />
                                </span>
                                <span className="text-[36px] lg:text-[38px]">
                                    {item?.suffix}
                                </span>
                                <span className="text-[18px] lg:text-[24px] font-bold ml-[8px]">
                                    {item?.suffixLabel}
                                </span>
                            </div>
                        </div>
                        <div className='text-[18px] lg:text-[24px] font-[500]'>
                            {item?.label}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className='bg-white'>
            <div className="py-[10px] sm:py-[40px]  container mx-auto">
                <div
                    // onClick={() => start()}
                    ref={ref}
                >
                    <div className="flex justify-center sm:justify-between flex-wrap sm:flex-nowrap sm:px-[20px]">
                        {homePage?.display_numbers?.map((item, index) => (
                            <Box item={item} key={item.label} index={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


