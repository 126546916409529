import React from 'react'
import MarkdownView from 'react-showdown'

import { SecondaryCard } from 'components/Cards'
import { SocialIconsWhite } from 'components/Footer/Socialcons'


export default function Content(props) {
    
    const {pageData} = props


    return (
        <div className='container mx-auto'>
            <div className="pt-[40px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-16 font-[400] font-[16px]">
                    <div className="leading-[28px]">
                        <MarkdownView markup={pageData?.description1} />
                    </div>
                    <div className="leading-[28px]">
                        <MarkdownView markup={pageData?.description2} />
                    </div>

                </div>

            </div>
            <div className="pt-[60px] lg:pt-[60px]">
                <SecondaryCard className="pt-[65px] pb-[47px] lg:pt-[26px] lg:pb-[30px] px-[20px]   bg-blue-factory">
                    <div className="max-w-[650px] mx-auto text-[16px] inter font-[500] leading-[26px]">
                        <MarkdownView markup={pageData?.description3} />
                        <div className="pt-[20px] mx-auto w-fit">
                            <SocialIconsWhite social={pageData?.social} />
                        </div>
                    </div>
                </SecondaryCard>
            </div>
            
        </div>
    )
}
