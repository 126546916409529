import React, {useRef} from 'react'


// swiper
import Swiper from 'react-id-swiper';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css/bundle';

import { getImage } from 'components/getImage'
import { ReactComponent as NextIcon } from 'assets/buttons/next.svg';


export default function Slider(props) {
    
    const {item} = props

    const swiperRef = useRef(null);
    SwiperCore.use([Autoplay, Navigation]);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };


    const params = {
        // slidesPerView: 'auto',
        // centeredSlides: false,
        slidesPerView: 4,
        loop: true,
        observer: true,
        spaceBetween: 30,
        on: {
            // realIndexChange: (swiper) => setImageIndex(swiper.realIndex)
        },
        autoplay: {
            delay: 1500,
            disableOnInteraction: false,
        },

    }

    const goToWebsite = (_link) => {
        if(!_link){
            return
        }
        const newWindow = window.open(_link, '_blank', 'noopener,noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }


    

    return (
        <>
        <div className="container mx-auto  w-full relative hidden sm:block   ">
            <div className="absolute left-[40px] top-[25px]  -translate-x-[100px] rotate-180">  <NextIcon onClick={() => goPrev()} className="cursor-pointer" /> </div>
            <div className="absolute right-[40px]  top-[25px]  translate-x-[100px]"> <NextIcon onClick={() => goNext()} className="cursor-pointer" /> </div>
        </div>
        <div className="container overflow-hidden mt-[44px]">

            {item.length > 0 &&
                <Swiper {...params} ref={swiperRef}>
                    {item.map((_item) => (
                    <div id={_item.id} className="w-fit   ">
                        <div 
                            onClick={() => goToWebsite(_item.website)}
                            className='h-full  hover:scale-[1.2] py-[10px] transition cursor-pointer flex flex-col justify-center items-center  bg-white  max-h-[90px] h-[90px] px-[10px] rounded-[10px]'>
                            <img 
                                src={getImage(_item?.image?.url, "assets/coinvesters/1.png")}
                                className="   max-h-[70px] w-fit mx-auto " />
                         </div>
                    </div>))
                    }

                </Swiper>
            }
        </div>

        </>
    )
}
