import React from 'react'
import { MutatingDots } from 'react-loader-spinner'

export default function Loading(props) {
    

    return (
        <div className='w-fit mx-[0px] flex items-center font-bold text-[14px] text-slate-500 '>
        <MutatingDots
            height="100"
            width="100"
            // color="#194081"
            color="#1D337A"
            secondaryColor='#7CC7DE'
            radius='9'
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass="-scale-75"
            visible={true}
        />
        <div className="animate-pulse">
       {props.text || "Loading..."}
        </div>
    </div>
    )
}
