import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet';
// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

import Hero from './Hero'
import Content from './Content'

// import { newsPage } from './data'
import { useScrollBehaviours } from 'components/useScrollBehaviours'

import useStoreData from 'store/storeData'

import InitEvents from './initNews';

export default function Index(props) {

    const { ScrollToTop } = useScrollBehaviours()
    const { storeData } = useStoreData()
    const newsPage = storeData?.newsPage || {}
    const newsList = storeData?.newsList || {}


    const { fetchdata, loading : newsLoading} = InitEvents()

    useEffect(() => {
        if(!newsList?.items){
            fetchdata()
        }
    },[])

    useEffect(() => {
        ScrollToTop()
    }, [])

    return (
        <>
         <Helmet>
            <title> News - BANA ANGELS – Business Angel Network of Armenia  </title>
            <meta
                 name="description"
                 content="We provide a platform for investors to meet and cooperate with their peers, and connect them with promising startups and entrepreneurs."
                 />
        </Helmet>
            <NavBar />
            <Hero pageData={newsPage} />
            <Content pageData={newsPage} loading={newsLoading} newsList={newsList} />
            <Footer />
        </>
    )
}
