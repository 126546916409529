import React, { useRef, useState } from 'react'

import { ReactComponent as NextIcon } from 'assets/buttons/next.svg';
import Secondary from 'components/Buttons/Secondary'


// swiper
import Swiper from 'react-id-swiper';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css/bundle';

// card
import useMobile from 'components/useMobile'
import { getImage } from './getImage';

const IconLinkedin = () => <svg xmlns="http://www.w3.org/2000/svg" fill='white' width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>

export default function TeamCard(props) {

    const { teamList = [], loop = true } = props
    // console.log(props)

    const { isMobile } = useMobile()

    let _teamList = teamList

    const [imageIndex, setImageIndex] = useState(0)
    const [showMore, setShowmore] = useState(false)

    // console.log(teamList)

    const swiperRef = useRef(null);
    SwiperCore.use([Autoplay, Navigation]);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 3,
        // centeredSlides: true,
        // slidesPerView: 3,
        loop: loop,
        observer: true,
        spaceBetween: 20,
        on: {
            realIndexChange: (swiper) => setImageIndex(swiper.realIndex)
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        breakpoints: {
            550: {
                slidesPerView: 3,
            }
        }

    }

    // console.log(teamList.length > 3 || loop)

    if(isMobile && !showMore){
        _teamList = [..._teamList].slice(0,3)
    }

    return (
        <div className="">
            {isMobile ? <div> 
                <div className="mobile  ">
                    {_teamList.map(RenderCard)}
                
                </div> 
                {
                    teamList.length > 3 &&
                    <div className="pt-[20px]">
                    <Secondary 
                        onClick={() => setShowmore(!showMore)} 
                        label={showMore ? "See less" :"See more"} 
                        className="inter w-full mx-auto md:max-w-[400px] h-[46px] font-[600] text-[18px]" 
                        />
                </div>
                }
            </div>:
                <div className="desktop  pt-[16px] relative">
                    {
                        (teamList.length > 3 || loop) &&
                        <div className="container mx-auto  h-full w-full absolute hidden sm:block">
                            <div className="absolute left-0 top-[calc(50%-30px)]  -translate-x-[60px] rotate-180">  <NextIcon onClick={() => goPrev()} className="cursor-pointer" /> </div>
                            <div className="absolute right-0  top-[calc(50%-30px)]  translate-x-[60px]"> <NextIcon onClick={() => goNext()} className="cursor-pointer" /> </div>
                        </div>
                    }
                    <div className="overflow-hidden  " >
                        {_teamList.length > 0 &&
                            <Swiper {...params} ref={swiperRef}>
                                {_teamList.map(RenderCard)}

                            </Swiper>
                        }
                    </div>

                </div>
            }

        </div>
    )
}

export const RenderCard = (item, index) => {
    return (
        <div key={item?.id} className='pt-[10px] lg:pt-0 relative mx-auto max-w-[400px]'>
            {/* {console.log(imageIndex, index)} */}
            <div className="center-card relative">
                <div className="relative rounded-2xl overflow-hidden  group">
                    <div className="invisible  group-hover:visible bg-[#1D337A]/30  text-white absolute w-full h-full px-[20px] py-[50px]">
                        <div className='text-[14px] font-[400] leading-[24px]'>
                        {item?.details}
                        </div>
                        <div className='font-bold text-[16px] pt-[8px]'>
                            <a href={item.linkedin} target="_blank" className='flex items-center'>
                               <IconLinkedin />
                               {/* <svg  width="24" fill='white' height="16" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> */}
                            </a>
                        </div>
                    </div>
                    <img className="w-full md:w-[400px]" src={getImage(item?.photo?.url, "assets/team/demo.png")} alt="" />
                </div>
                <div className="font-bold pt-[10px] lg:pt-[15px] text-[20px] sm:text-[16px] lg:text-[24px]">
                    {item?.name}
                </div>
                <div className="opacity-90 pt-[] font-[600] opacity-[0.6] text-[16px] sm:text-[12px] lg:text-[16px]">
                    {item?.position}
                </div>
                {/* {item?.name} */}
            </div>
        </div>
    )
}

export const RenderCardSmall = (item, index) => {
    return (
        <div key={item?.id} className='pt-[10px] lg:pt-0 relative mx-auto max-w-[400px]'>
            {/* {console.log(imageIndex, index)} */}
            <div className="center-card relative">
                <div className="relative rounded-2xl overflow-hidden  group">
                    <div className="invisible  group-hover:visible bg-[#1D337A]/30  text-white absolute w-full h-full px-[20px] py-[50px]">
                        <div className='text-[14px] font-[400] leading-[24px]'>
                        {item?.details}
                        </div>
                        <div className='font-bold text-[16px] pt-[8px]'>
                            <a href={item.linkedin} target="_blank" className='flex items-center'>
                               <IconLinkedin />
                               {/* <svg  width="24" fill='white' height="16" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> */}
                            </a>
                        </div>
                    </div>
                    <img className="w-full md:w-[400px]" src={getImage(item?.photo?.url, "assets/team/demo.png")} alt="" />
                </div>
                <div className="font-bold pt-[10px] lg:pt-[15px] text-[20px] sm:text-[16px] ">
                    {item?.name}
                </div>
                <div className="opacity-90 pt-[] font-[600] opacity-[0.6] text-[16px] sm:text-[12px] ">
                    {item?.position}
                </div>
                {/* {item?.name} */}
            </div>
        </div>
    )
}