import React, {createContext, useReducer} from 'react';
// import { _carsList } from 'Pages/_carsList';

// import { homePage } from 'pages/Home/data';

const stateModal = {
    navBar : {},
    homePage : {},
    aboutPage : {},
    factoryPage : {},
    eventsPage : {},
    forInvestorsPage : {},
    forStartupsPage : {},
    footerData : {},
    newsPage : {},

    projectsList : {},
    eventList : {},
    newsList : {},
    
    sydicatePage : {}, 
    sapStartupPage : {},
    
  }
  
  const initialState = {...stateModal};

  const Store = createContext(initialState);

  const { Provider } = Store;


const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer((state, action) => {
      let newState = {}
      // console.log(action)
      switch(action.type) {
        case 'initAll':
            newState = {
                ...state, 
                homePage : { 
                    ...state?.homePage, 
                    ...action?.payload?.homePage,
                },
                aboutPage : {
                  ...state?.aboutPage, 
                  ...action?.payload?.aboutPage,
                },
                factoryPage : {
                  ...state?.factoryPage, 
                  ...action?.payload?.factoryPage,
                },
                eventsPage : {
                  ...state?.eventsPage, 
                  ...action?.payload?.eventsPage,
                },
                forInvestorsPage : {
                  ...state?.forInvestorsPage, 
                  ...action?.payload?.forInvestorsPage,
                },
                forStartupsPage : {
                  ...state?.forStartupsPage, 
                  ...action?.payload?.forStartupsPage,
                },
                newsPage : {
                  ...state?.newsPage, 
                  ...action?.payload?.newsPage,
                },
                sydicatePage : {
                  ...state?.sydicatePage, 
                  ...action?.payload?.sydicatePage,
                },
                sapStartupPage : {
                  ...state?.sapStartupPage, 
                  ...action?.payload?.sapStartupPage,
                },
                footerData : {
                  ...state?.footerData, 
                  ...action?.payload?.footerData,
                },
                navBar : {
                  ...state?.navBar, 
                  ...action?.payload?.navBar,
                },
            }
            return newState;
        case 'intProjects' :
          newState = {
            ...state, 
            projectsList : { 
                ...action?.payload?.projectsList,
              }
            }
          return newState
        case 'initEvents' :
          newState = {
            ...state, 
            eventList : { 
                ...action?.payload?.eventList,
              }
            }
          return newState
        case 'initNews' :
          newState = {
            ...state, 
            newsList : { 
                ...action?.payload?.newsList,
              }
            }
          return newState
        case 'clearState' :
          return {  ...stateModal  }
        default:
          throw new Error();
      };
    }, initialState);
  
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
  };
  

  export { Store , StateProvider }