import React from 'react'
import TeamCard from 'components/TeamCard'

// import Testmonials from '../Testmonials/testmonials'

import { SlideLeft, SlideRight, SlideBottom, SlideTop, JustAppear} from 'components/SlideAnimation'
import { SubHeading } from 'components/Headings'




export default function Founders(props) {

    const { homePage } = props

    return (
        <div className='container mx-auto '>
            <div className="mt-[60px] lg:mt-[100px]">
                <SlideBottom>
                    <SubHeading className="inter  text-center">
                    {/* <div className="inter text-[32px] text-secondary text-center font-bold"> */}
                        {homePage?.founders?.heading}
                    {/* </div> */}
                    </SubHeading>
                </SlideBottom>
            </div>
            <div className=" mt-[8px] lg:mt-0">
                <TeamCard teamList={homePage?.founders?.teamList} />
            </div>
           
        </div>
    )
}
