import React from 'react'
import MarkdownView from 'react-showdown'
import { SecondaryCard } from 'components/Cards'

export default function JoinUs(props) {

    const { pageData } = props

    return (
        <div  className='pt-[40px] container mx-auto'>
            <div id="join-us" className='font-[400] text-[16px] leading-[28px]'>
            <MarkdownView markup={pageData?.description} />
            </div>
            <SecondaryCard className="pt-[65px] pb-[50px]  lg:pt-[20px] px-[20px] lg:pb-[40px] mt-[60px]  bg-blue-applynow ">
                <div  className="text-[24px] lg:text-[20px] font-bold">
                    <MarkdownView markup={pageData?.joinUs?.heading} />
                </div>
                <div className="pt-[14px] lg:pt-[20px]">
                    <MarkdownView markup={pageData?.joinUs?.description} />
                </div>
            </SecondaryCard>
        </div>
    )
}
