import React from 'react'
import Testmonials from './testmonials'

import { SlideBottom } from 'components/SlideAnimation'

export default function Index(props) {

    const { homePage } = props

    return (
            <div className="container mx-auto">
                <div className="pt-[60px] lg:pt-[100px]">
                    <SlideBottom>

                    <div className="text-center inter text-[24px] lg:text-[32px] font-bold text-secondary">
                        {homePage?.testmonials?.heading}
                    </div>
                    </SlideBottom>
                </div>
                <Testmonials homePage={homePage} />
            </div>
    )
}
