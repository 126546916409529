import { getImage } from 'components/getImage'
import React from 'react'
import { Link, useNavigate, NavLink } from 'react-router-dom'
import { navbar } from './data'

export default function Desktop(props) {

    const { transparent = false, floatActivate = false, isfloatingNav = false, navBarLogo={} } = props

    const logoURL = getImage(navBarLogo?.logo_white?.url, navbar?.logoURL)
    const logoBlueURL =  getImage(navBarLogo?.logo_blue?.url , navbar?.logoBlueURL)

    const navigate = useNavigate()

    const getTextColor = (active) => {
        // const active = false
        if(active){
            
            return  "text-white bg-[#1D337A] custom-fill-active "

        } else {
            return  ` hover:text-[#1D337A] hover:bg-[#a0e3e87c]   hover:rounded-  ${transparent ? " text-white custom-fill-transparent" : " text-primary custom-fill"}`
        }
    }


    return (
        <>
            <div className={`px-3 ${transparent ? "" : "bg-white"} 
          ${isfloatingNav ?
                    `fixed w-full  bg-white z-40 drop-shadow-md  ${floatActivate ? 'translate-y-0' : '-translate-y-44'}`
                    : ''
                } 
            transition-transform duration-1000
            `}>
                <div className="container mx-auto">
                    <div className="flex justify-between items-center">
                        <div className="logo ">
                            <div onClick={() => navigate("/")} className="cursor-pointer" >
                                <img src={`${transparent ? logoURL : logoBlueURL}`} alt="" className='' />
                            </div>
                        </div>
                        <div className="flex text-[14px] lg:text-[16px] justify-right">
                            {navbar?.menuList?.map((item) => {
                                return (<div key={item.title} className='relative group cursor-pointer w-fit inter'>
                                    <NavLink
                                        key={item?.title} 
                                        to={item?.url}
                                        // onClick={() => navigate(item?.url)} 
                                        className={({isActive}) => ` py-[30px] px-[6px] md:px-[10px] font-[400]  ${getTextColor(isActive)} flex items-center`}>
                                        <span to={item?.url}>
                                            {item.title}
                                        </span>
                                        {/* fill={transparent ? 'white' : "#1D337A"} */}
                                        {item.children && <svg className={`ml-[8px] rotate-[90deg] `} xmlns="http://www.w3.org/2000/svg" width="10" height="10"  viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /></svg>}
                                    </NavLink>
                                    {/* if its a drop down */}
                                    {item.children && <>
                                    <div className='absolute z-40 hidden group-hover:block top-0 left-0 w-full h-[40px]'></div>
                                    <div className="absolute z-40 hidden group-hover:block  top-[60px] left-[60px]  w-[26px] overflow-hidden inline-block">
                                        <div className=" h-[20px] w-[20px]      bg-white shadow border rotate-45 transform origin-bottom-left"></div>
                                    </div>
                                    <div className="absolute z-40 hidden  group-hover:block   top-[80px] left-[25%] bg-white text-center shadow bg-white rounded-lg border min-w-[100px]">
                                        
                                        {
                                        item.children.map(_item => (
                                            <div key={_item?.title} onClick={() => navigate(_item?.url)} className={` px-[40px] font-medium  hover:bg-[#a0e3e87c]  rounded-lg  text-primary text-center py-[10px]`}>
                                            <span  className="h-full w-full">
                                                {_item.title}
                                            </span>
                                        </div>))
                                        }
                                    </div>
                                    </>}
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
