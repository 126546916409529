import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

// pages
import Hero from './Hero'
import JoinUs from './JoinUs'
import Form from './Form'
import Investors from './Investors'

// import { forInvestorsPage } from './data'

import { useScrollBehaviours } from 'components/useScrollBehaviours'
import useStoreData from 'store/storeData'
import {InvestForm} from "./InvestForm";

export default function Index(props) {

    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()
    const { storeData } = useStoreData()
    const forInvestorsPage = storeData?.forInvestorsPage || {}


    useEffect(() => {
        if (location.hash.slice(1)) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {

            ScrollToTop()
        }
        // ScrollToTop()
    }, [location])


    return (
        <>
            <Helmet>
                <title> For Investors - BANA – Business Angel Network of Armenia  </title>
                <meta
                    name="description"
                    content="Our network consists of experienced investors and prominent business leaders from Armenia and abroad who have succeeded in a variety of fields and are leveraging their capital and expertise to foster startups’ growth all the way to an exit. They benefit from the connection to a group of high-impact individuals with cutting-edge knowledge and powerful professional networks in exchange for the possibility of high financial returns."
                />
            </Helmet>
            <NavBar />
            <Hero pageData={forInvestorsPage} />
            <JoinUs pageData={forInvestorsPage} />
            <Form />
            <Investors pageData={forInvestorsPage} />
            <InvestForm />
            <Footer />
        </>
    )
}