import React from 'react'

export default function Primary(props) {
    
    const {label = "Button", onClick = () => {}, className = "", color="bg-[#1D337A]"} = props

    return (
        <div 
            onClick={onClick}
            className={`flex flex-col items-center justify-center  text-white ${color} rounded-[12px] p-2 text-center font-bold cursor-pointer ${className}`}>
            {label}
        </div>
    )
}
