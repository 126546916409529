import React, {useContext, useEffect, useState} from 'react'
import { Routes, Route} from "react-router-dom";
import {  Store } from 'store/websiteDataStore'

import axios from 'axios';


// pages
import Home from './pages/Home'
import About from './pages/About'
import ForInvestors from './pages/ForInvestors'
import ForStartups from './pages/ForStartups'
import Factory from './pages/Factory'
import Syndicate from 'pages/Factory/Syndicate';
import SapStartupFactory from 'pages/Factory/SapStartupFactory';

import Events from './pages/Events'
import Viewer from './pages/Viewer'
import ProjectViewer from './pages/Viewer/Projects'
import News from './pages/News'
import NotFound from './pages/404'

import {MutatingDots} from 'react-loader-spinner'
import {ReturnUrl} from "./pages/ReturnUrl/ReturnUrl";

export default function RoutesRender(props) {

    const routesList = [
        {
            path: '/',
            component: <Home />
        },
        {
            path: '/about',
            component: <About />
        },
        {
            path: '/for-investors',
            component: <ForInvestors />
        },
        {
            path: '/for-startups',
            component: <ForStartups />
        },
        {
            path: '/the-factory',
            component: <Factory />
        },
        {
            path: '/the-factory/project/:id',
            component: <ProjectViewer />
        },
        {
            path: '/the-factory/syndicate-by-bana',
            component: <Syndicate />
        },
        {
            path: '/the-factory/sap-startup-factory',
            component: <SapStartupFactory />
        },
        {
            path: '/events',
            component: <Events />
        },
        {
            path: '/news',
            component: <News />
        },
        {
            path: '/:type/viewer/:id',
            component: <Viewer />
        },
        {
            path: '/return-url',
            component: <ReturnUrl />
        },
        {
            path: '*',
            component: <NotFound />
        },

    ]

    const _store = useContext(Store)
    const [loading, setLoading] = useState(true)
    const [error, seterror] = useState(false)

  const fetchAlldata = async() => {
    try{
      setLoading(true)
      const res = await axios.get(`${process.env.REACT_APP_URL}/api/home-page/getAllconfigs`)
      // const data = await res.json()
      // console.log(res)
      _store.dispatch(
        {
          type: 'initAll',
          payload: res?.data?.data
        }
      )
      setLoading(false)
    } catch(ex){
      seterror(true)
    }
  }

  useEffect(() => {
    fetchAlldata()
  }, [])


    return (
        <>
        {loading ?
        <div className='fixed left-0 top-0 h-screen w-screen flex flex-col items-center justify-center bg-[#595EA4]'>
            <MutatingDots
              height="100"
              width="100"
              // color="#194081"
              color="white"
              secondaryColor='#7CC7DE'
              radius='12.5'
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <div className="text-[16px] font-bold text-white tracking-wider rounded-xl border-4 py-[1px] px-2">
              LOADING
            </div>
        </div>:
         <Routes>
          {routesList.map(link => <Route key={link.path} path={link.path} element={link.component} />)}
         </Routes>
        }
        </>
    )
}
