import React, {useState} from 'react'

import usescrollpos from 'components/useScrollPos'


import Desktop from './Desktop'
import Mobile from './Mobile'
import SideBar from './SideBar'

import { navbar } from './data'

import useStoreData from 'store/storeData'

export default function Index(props) {

    const { isScrollBeyond } = usescrollpos()

    const {storeData} = useStoreData()
    const navBarLogo = storeData?.navBar || {}

    const floatActivate = isScrollBeyond(150)
    const [open, setOpen] = useState(false)

    const openSideBar = () => {
        setOpen(true)
    }

    const closeSidebar = () => {
        setOpen(false)
    }

    return (
        <>
        <div className="hidden md:block text-center">
           <Desktop {...props} navBarLogo={navBarLogo}  transparent={false}  isfloatingNav={true} floatActivate={floatActivate} /> 
           <Desktop {...props} navBarLogo={navBarLogo} /> 
        </div>
        <div className="block md:hidden">
           <Mobile {...props} navBarLogo={navBarLogo} openSideBar={openSideBar} transparent={false}  isfloatingNav={true} floatActivate={floatActivate} /> 
           <Mobile {...props} navBarLogo={navBarLogo} openSideBar={openSideBar} /> 
        </div>
        <SideBar navData={navbar} open={open}  closeSidebar={closeSidebar} />
        </>
    )
}
