import React, {useEffect, useState, useContext} from 'react'
import {  Store } from 'store/websiteDataStore'


export default function InitEvents(props) {
    
    const _store = useContext(Store)

    const [loading, setLoading] = useState(false)
    const [error, seterror] = useState(false)

    const fetchdata = async() => {
        try{
          setLoading(true)
          const res = await fetch(`${process.env.REACT_APP_URL}/api/home-page/events`)
          const data = await res.json()
          // console.log(data)
          _store.dispatch(
            {
              type: 'initEvents',
              payload: data?.data
            }
          )
          setLoading(false)
        } catch(ex){
          seterror(true)
        }
    }

    // useEffect(() => {
    //     // fetchdata()
    // }, [])

    return {fetchdata, loading, error}
}
