import React from 'react'
import CountUp, {useCountUp} from 'react-countup';
import { useInView } from "react-intersection-observer";

export default function Numbers(props) {

    const { homePage } = props

    const [ref, inView] = useInView();

    const Box = ({ item, index }) => {
        return (
            <div className={`box  pt-0 sm:pt-[20px] md:pt-0 w-full lg:w-fit ${index === 1 ? "mt-[20px] sm:mt-0 sm:pr-[40px]" : ""}`}>
                <div className=" text-white">
                    <div className='inter text-center'>
                        <div className="font-bold ">
                            <div className="">
                                <span className="text-[32px] lg:text-[60px] lg:leading-[38px]">
                                    {item?.prefix}
                                </span>
                                <span className="text-[32px] lg:text-[60px] lg:leading-[38px]">
                                    <CountUp end={item?.value} />
                                </span>
                                <span className="text-[32px] lg:text-[60px] lg:leading-[38px]">
                                    {item?.suffix}
                                </span>
                            </div>
                        </div>
                        <div className=' text-[16px] md:text-[24px] lg:leading-[38px] font-[500]'>
                            {item?.label} 
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className="bg-primary">
            <div ref={ref} className="py-[14px] sm:py-[30px]  container mx-auto">

                <div className="flex justify-between  flex-wrap sm:flex-nowrap sm:px-[18px]">
                    {homePage?.display_numbers_2?.map((item, index) => (
                        <Box item={item} key={item.label} index={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}
