import React from 'react'
import MarkdownView from 'react-showdown'

import { ReactComponent as CheckBox } from 'assets/logo/checkBox.svg'

export default function Content(props) {
    
    const {pageData} = props

    return (
        <div className='container mx-auto '>
            <div className="max-w-[683px] mx-auto">
            <div className="pt-[40px]">
                <div className="font-[700] text-[16px] md:text-[24px]">
                    <MarkdownView markup={pageData?.for_angel_investors?.heading} />
                </div>
                <div className="">
                    {pageData?.for_angel_investors?.items?.map(item => {
                        return(<div key={item.id} className="inter pt-[20px] font-[400] lg:text-[14px] md:text-[16px]">
                            <div className="flex">
                                <div className='mr-[20px]'>
                                <CheckBox  />
                                </div>
                                <MarkdownView markup={item.item} className="max-w-[543px] " />
                            </div>
                        </div>)
                    })}
                </div>
                <div className="font-bold text-[16px] md:text-[24px] pt-[30px] lg:pt-[20px]">
                    <MarkdownView markup={pageData?.for_startups?.heading} />
                </div>
                <div className="">
                    {pageData?.for_startups?.items?.map(item => {
                        return(<div key={item.id} className="pt-[20px]  text-[14px] md:text-[16px]">
                            <div className="flex">
                                <div className='mr-[20px]'>
                                <CheckBox  />
                                </div>
                                <MarkdownView markup={item.item} />
                            </div>
                        </div>)
                    })}
                </div>
            </div>
            </div>
        </div>
    )
}
