import { getImage } from 'components/getImage'
import React from 'react'
import MarkdownView from 'react-showdown'
import { SlideTop } from 'components/SlideAnimation'

export default function Hero(props) {
    
    const {pageData} = props

    const _style = {
        mainBg : {
            background : `url(${getImage(pageData?.background?.url, "assets/backgrounds/factory.svg")})`,
            backgroundSize : 'cover',
            backgroundPosition : 'center',
            backgroundRepeat : 'no-repeat'
        },
        logoBg : {
            backgroundColor : "#1D337A",
        }
    }


    return (<>
    {
        pageData?.background?.url ?
        <>
         <div style={_style?.mainBg} className="relative h-[180px] lg:h-[300px]" >
        <div className="absolute h-full w-full bg-[#1D337A4D] "></div>
        <div  className='absolute w-full h-full left-0 top-0 '>
            <div className="container mx-auto  h-full">
            <div className=" flex flex-col  h-full items-center justify-center text-white font-bold ">
                <div className="inter text-[32px] lg:text-[48px] leading-[42px] font-[700]">
                    <SlideTop>
                        {pageData?.logo?.url ? 
                            <img src={getImage(pageData?.logo?.url, "assets/backgrounds/factory.svg")} alt='the factory' />
                            : 
                            <MarkdownView markdown={pageData?.heading} />
                        }
                    </SlideTop>
                </div>
            </div>
            </div>
        </div>
        
    </div>
    </> 
        :
        <>
         <div  style={ pageData?.bgMainURL ? _style?.mainBg : _style?.logoBg} >
            <div  className='container mx-auto h-[180px] lg:h-[300px]'>
                <div className=" flex flex-col items-center justify-center text-white font-bold h-full">
                   <img src="assets/backgrounds/factory.svg"  alt={pageData?.background?.url} className='w-[65px] lg:w-fit' />
                </div>
            </div>
            
        </div>
        </>
    }
       
    </>
    )
}
