import React from 'react'

import Twitter from 'assets/logo/twitterBlue.svg'
import YouTube from 'assets/logo/youtubeBlue.svg'
import FB from 'assets/logo/fbBlue.svg'
import LinkedIn from 'assets/logo/LinkedInBlue.svg'
import Instagram from 'assets/logo/instgramBlue.svg'

import _Twitter from 'assets/logo/twitter.svg'
import _YouTube from 'assets/logo/youtube.svg'
import _FB from 'assets/logo/fb.svg'
import _LinkedIn from 'assets/logo/LinkedIn.svg'
import _Instagram from 'assets/logo/instgram.png'

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    
  } from "react-share";

// import Exit from 'assets/logo/exit.svg'

// import { footer } from './data'
import useStoreData from 'store/storeData'


export default function SocialconsBlue(props) {

    const {social} = props
    const {storeData} = useStoreData()
    // const footerData =  storeData?.footerData || {}
    const footerData =  social ? {social}  : storeData?.footerData 

    // console.log(footerData)

    return (
        <>
            <div className="flex w-fit">
                {
                    footerData?.social?.twitter && <div> <a target="_blank" href={footerData?.social?.twitter}> <img className='mr-3' src={Twitter} alt="" /> </a> </div>
                }
                {
                    footerData?.social?.youtube && <div> <a target="_blank" href={footerData?.social?.youtube}> <img className='mr-3' src={YouTube} alt="" /> </a> </div>
                }
                {
                    footerData?.social?.facebook && <div> <a target="_blank" href={footerData?.social?.facebook}> <img className='mr-3' src={FB} alt="" /> </a> </div>
                }
                {
                    footerData?.social?.linkedin && <div> <a target="_blank" href={footerData?.social?.linkedin}> <img className='mr-3' src={LinkedIn} alt="" /> </a> </div>
                }
                {
                    footerData?.social?.instagram && <div> <a target="_blank" href={footerData?.social?.instagram}> <img className='mr-3' src={Instagram} alt="" /> </a> </div>
                }
            </div>

        </>
    )
}

export const SocialIconsWhite = (props) => {

    const { social = {} } = props

    return (
        <>
            <div className="flex w-fit">
                {
                    social?.twitter && <div> <a target="_blank" href={social?.twitter}> <img className='mr-3 max-h-[30px]' src={_Twitter} alt="" /> </a> </div>
                }
                {
                    social?.youtube && <div> <a target="_blank" href={social?.youtube}> <img className='mr-3 max-h-[30px]' src={_YouTube} alt="" /> </a> </div>
                }
                {
                    social?.facebook && <div> <a target="_blank" href={social?.facebook}> <img className='mr-3 max-h-[30px]' src={_FB} alt="" /> </a> </div>
                }
                {
                    social?.linkedin && <div> <a target="_blank" href={social?.linkedin}> <img className='mr-3 max-h-[30px]' src={_LinkedIn} alt="" /> </a> </div>
                }
                {
                    social?.instagram && <div> <a target="_blank" href={social?.instagram}> <img className='mr-3 max-h-[30px]' src={_Instagram} alt="" /> </a> </div>
                }
            </div>

        </>
    )
}

export const FooterShareIcons = (props) => {

    const {url = "", heading = "", hashtag=""} = props

    return(
        <div className="flex">
            <div>
                <FacebookShareButton  url={url} quote={heading} hashtag={"BanaAngels"}  >
                <img className='mr-3' src={FB} alt="" />
                </FacebookShareButton>
            </div>
            <div>
                <TwitterShareButton url={url} title={heading} hashtags={["BanaAngels"]} >
                <img className='mr-3' src={Twitter} alt="" />
                </TwitterShareButton>
            </div>
            <div>
                <LinkedinShareButton url={url} title={heading} source={"Bana Angels"} >
                <img className='mr-3' src={LinkedIn} alt="" />
                </LinkedinShareButton>
               
            </div>
        </div>
    )
}