import { getImage } from 'components/getImage'
import React from 'react'
import MarkdownView from 'react-showdown'

export default function Hero(props) {
    
    const {pageData} = props

    const _style = {
        mainBg : {
            background : `url(${pageData?.bgMainURL})`,
            backgroundSize : 'contain',
            backgroundPosition : 'center',
            backgroundRepeat : 'no-repeat'
        },
        logoBg : {
            backgroundColor : "#1D337A",
        }
    }


    return (
        <div style={ pageData?.bgMainURL ? _style?.mainBg : _style?.logoBg} >
            <div  className='container mx-auto'>
                <div className="h-[180px] lg:h-[300px] flex flex-col items-center justify-center text-white font-bold">
                   <img src={getImage(pageData?.logo?.url, "/assets/backgrounds/syndicate_big.svg")} className="mx-auto w-[230px] sm:w-[400px] md:w-fit"  alt="bana" />
                </div>
            </div>
            
        </div>
    )
}
