import React from 'react'


import { SecondaryCard } from 'components/Cards'
import Primary from 'components/Buttons/Primary'
import FooterIcons from 'components/Footer/Socialcons'
import { getImage } from 'components/getImage'


export default function Gallary(props) {

    const { pageData } = props

    return (
        <div className='container mx-auto'>
            <div className="max-w-[683px] mx-auto">
                {
                    pageData?.gallary?.items?.length > 0 &&
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[20px] lg:gap-x-[16px] gap-x-[20px] py-[60px] lg:py-[30px]">
                        {pageData?.gallary?.items?.map(item => {
                            return (<div key={item.id} className="relative rounded-xl overflow-hidden">
                                <div className="absolute left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div>
                                <img src={getImage(item.image?.url, "/assets/sample/projects.png")} alt="" className=' w-full' />

                            </div>)
                        })}
                    </div>
                }
                <SecondaryCard className="py-[36px] lg:py-[20px] bg-blue-applynow">
                    <div className="text-[16px] font-[500] inter">
                        {pageData?.gallary?.description}
                    </div>
                    <div className='pt-[20px]'>
                        <a href={pageData?.gallary?.website} target="_blank">
                        <Primary label="Go to website" className="mx-auto max-w-[188px]" />
                        </a>
                    </div>
                </SecondaryCard>
                <div className="mt-[60px] lg:mt-[20px]  relative w-full overflow-hidden  pt-[56.25%] rounded-2xl">
                    <iframe className='absolute w-full h-full top-0 left-0 bg-slate-300' src={pageData?.gallary?.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="pt-[20px]">
                    <FooterIcons />
                </div>
                <div className="pb-[60px]"></div>
            </div>
        </div>
    )
}
