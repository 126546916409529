import React from 'react'
import { useNavigate } from 'react-router-dom'

import ButtonSecondary from 'components/Buttons/Secondary'
import { SlideBottom, SlideLeft, SlideTop } from 'components/SlideAnimation'
import { getImage } from 'components/getImage'

export default function Syndicate(props) {

    const { homePage } = props
    const navigate = useNavigate()

    return (
        <div className=' bg-blue-pattern3 '>
            <div className="container mx-auto py-[44px]">
                <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-1/2 ">
                        <SlideTop>
                            <img src={getImage(homePage?.Syndicate?.logo?.url, "assets/logo/syndicate.svg")} alt="syndicate" className='w-full  w-[290px] lg:w-[372px] lg:h-[96px] 3xl:w-[450px] 3xl:h-fit ' />
                        </SlideTop>
                    </div>
                    <div className="w-full  pl-[20px] md:w-1/2 text-white">
                        <SlideBottom>
                            <div className="md:pt-0 pb-[14px] text-[14px] 3xl:text-[16px] leading-[22px] pt-[20px] max-w-[320px]  lg:max-w-[387px] 3xl:max-w-[450px]">
                                {homePage?.Syndicate?.description}
                            </div>
                        </SlideBottom>
                        <div className="">
                            {
                                homePage?.Syndicate?.is_external_link ?
                                    <SlideLeft>
                                        <a href={`${homePage?.Syndicate?.link}`} target="_blank">
                                            <ButtonSecondary  label="Learn more" className="w-full h-[46px]  md:max-w-[216px]" />
                                        </a>
                                    </SlideLeft>
                                    :
                                    <SlideLeft>
                                        <ButtonSecondary onClick={() => navigate(`${homePage?.Syndicate?.link}`)} label="Learn more" className="w-full h-[46px]  md:max-w-[216px]" />
                                    </SlideLeft>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
