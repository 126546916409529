import React from 'react'
import MarkdownView from 'react-showdown'

import { ReactComponent as CheckBox } from 'assets/logo/checkBox.svg'

export default function Content(props) {
    
    const {pageData} = props

    return (
        <div className='container mx-auto '>
            <div className="max-w-[683px] mx-auto">
            <div className="pt-[40px]">
                <div className="font-[400] text-[16px] md:text-[16px]">
                    <MarkdownView markup={pageData?.description} />
                </div>
                <div className="font-[700] text-[16px]  pt-[30px]">
                    <MarkdownView markup={pageData?.programs?.heading} />
                </div>
                <div className="mt-[14px]">
                    {pageData?.programs?.items?.map(item => {
                        return(<div key={item.id} className="inter pt-[8px] font-[400] lg:text-[14px] md:text-[16px]">
                            <div className="flex">
                                <div className='mr-[20px]'>
                                <CheckBox  />
                                </div>
                                <MarkdownView markup={item.item} className="max-w-[543px] " />
                            </div>
                        </div>)
                    })}
                </div>
                
            </div>
            </div>
        </div>
    )
}
