import React, { useState, useEffect } from 'react'

import useMobile from 'components/useMobile'
import Secondary from 'components/Buttons/Secondary'
import { SlideBottom } from 'components/SlideAnimation'
import { getImage } from 'components/getImage'

import Slider from './Slider'


export default function CoInvested(props) {

    const { homePage } = props
    const { isMobile } = useMobile()

    let conInvestedCompanies = homePage?.coInvested_companies?.conInvested_Companies || []

    const [showMore, setShowmore] = useState(false)

    if (isMobile && !showMore) {
        conInvestedCompanies = [...conInvestedCompanies].slice(0, 6)
    }

    const goToWebsite = (_link) => {
        if(!_link){
            return
        }
        const newWindow = window.open(_link, '_blank', 'noopener,noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }

    return (<div className='mx-auto container' >
        <div className="inter text-[24px] lg:text-[32px] text-secondary text-center font-bold">
            <SlideBottom>
                {homePage?.coInvested_companies?.heading}
            </SlideBottom>
        </div>
        {
            isMobile ? <div>
                <div className="mobile grid grid-cols-3 mx-auto gap-6 gap-y-[40px] mt-[25px]">
                    {conInvestedCompanies?.map(item => {
                        return (<img 
                                key={item.id}
                                onClick={() => goToWebsite(item.website)}
                                src={getImage(item?.image?.url, "assets/coinvesters/1.png")} 
                                className="mx-auto max-h-[70px] cursor-pointer" />)
                    })}
                </div>
                <div className="pt-[24px]">
                    <Secondary
                        onClick={() => setShowmore(!showMore)}
                        label={showMore ? "See less" : "See more"}
                        className="w-full mx-auto md:max-w-[400px] h-[46px]"
                    />
                </div>
            </div>
                :
                <div>
                    
                    <Slider item={conInvestedCompanies} />
                    {/* <div className='flex justify-center mt-[44px]  overflow-hidden'>
                        {conInvestedCompanies?.map(item => {
                            return (<img src={getImage(item?.image?.url, "assets/coinvesters/1.png")} className="mx-[24px]" />)
                        })}

                    </div> */}
                </div>
        }

    </div>
    )
}
