import { getImage } from 'components/getImage'
import React from 'react'
import MarkdownView from 'react-showdown'



export default function Hero(props) {

    const { pageData } = props

    const _style = {
        mainBg: {
            background: `url(${getImage(pageData?.background?.url, "/assets/backgrounds/about.png")})`,
            // backgroundColor : "#1D337A",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }


    return (
        <div style={_style?.mainBg} className="relative h-[180px] lg:h-[300px]" >
            <div className="absolute h-full w-full bg-[#1D337A4D] "></div>
            <div className='absolute w-full h-full left-0 top-0 '>
                <div className="container mx-auto  h-full">
                    <div className=" flex flex-col  h-full items-center justify-center text-white font-bold ">
                        <div className="inter text-[32px] lg:text-[48px] font-[700]">
                            <MarkdownView markdown={pageData?.heading} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
