import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet';
// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

import Content from './Content'

// import { eventsPage } from './data'
import { useScrollBehaviours } from 'components/useScrollBehaviours'
import useStoreData from 'store/storeData'

import InitEvents from './initEvents';


export default function Index(props) {

    const { ScrollToTop } = useScrollBehaviours()
    const { storeData } = useStoreData()
    const eventsPage = storeData?.eventsPage || []
    const eventList = storeData?.eventList || {}


    const { fetchdata, loading : eventLoading} = InitEvents()

    useEffect(() => {
        ScrollToTop()
    }, [])

    useEffect(() => {
        if(!eventList?.items){
            fetchdata()
        }
    },[])

    // console.log(eventsPage)

    return (
        <>
            <NavBar />
            <Helmet>
                <title> Events - BANA ANGELS – Business Angel Network of Armenia  </title>
                <meta
                    name="description"
                    content="We provide a platform for investors to meet and cooperate with their peers, and connect them with promising startups and entrepreneurs."
                />
            </Helmet>
            <Content pageData={eventsPage} loading={eventLoading} eventList={eventList} />
            <Footer />
        </>
    )
}
