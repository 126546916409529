import React from 'react'
import { Link } from 'react-router-dom'
import { navbar } from './data'
import { getImage } from 'components/getImage'


export default function Mobile(props) {

    const { transparent = false, navBarLogo={}, floatActivate = false, isfloatingNav = false, openSideBar } = props

    // const logoURL = navBarLogo?.logo_white ? navBarLogo?.logo_white : navbar?.logoURL
    // const logoBlueURL = navBarLogo?.logo_blue ? navBarLogo?.logo_blue : navbar?.logoBlueURL
    const logoURL = getImage(navBarLogo?.logo_white?.url, navbar?.logoURL)
    const logoBlueURL =  getImage(navBarLogo?.logo_blue?.url , navbar?.logoBlueURL)

    return (
        <>
            <div className={`p-3 ${transparent ? "" : "bg-white"} 
          ${isfloatingNav ?
                    `fixed w-full  bg-white z-40 drop-shadow-md  ${floatActivate ? 'translate-y-0' : '-translate-y-44'}`
                    : ''
                } 
            transition-transform duration-1000
            `}>
                <div className="container mx-auto">
                    <div className="flex justify-between items-center">
                        <div className="logo ">
                            <Link to="/">
                                <img src={`${transparent ? logoURL : logoBlueURL}`} alt="" className='w-[45px] ' />
                            </Link>
                        </div>
                        <div 
                             onClick={() => openSideBar()}
                            className=' py-2 pl-2'>
                            <div 
                               
                                className="w-fit h-[25px] flex flex-col justify-between cursor-pointer">
                                <div className={`h-[4px] w-[30px] ${transparent ?  "bg-white" : "bg-[#1D337A]"}`}></div>
                                <div className={`h-[4px] w-[30px] ${transparent ?  "bg-white" : "bg-[#1D337A]"}`}></div>
                                <div className={`h-[4px] w-[30px] ${transparent ?  "bg-white" : "bg-[#1D337A]"}`}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
