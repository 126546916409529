import { SlideBottom } from 'components/SlideAnimation'
import React from 'react'
import MarkdownView from 'react-showdown'

export default function Vision(props) {

    const {aboutPage} = props

    return (
        <div className='container mx-auto'>
            {aboutPage?.description &&
            <div className='pt-[40px]'>
                <SlideBottom>
                    <MarkdownView markup={aboutPage?.description} />
                </SlideBottom>
            </div>
            }
            <div className="">
                <div className="flex flex-wrap pt-[18px] lg:pt-0">
                    <div className="w-full md:w-1/2 pt-[20px]  lg:pt-[35px]">
                        <div className="inter text-center md:text-left text-[24px] md:text-[32px] font-[700] text-secondary"> 
                            <SlideBottom>
                            <MarkdownView markup={aboutPage?.mission?.heading} />
                            </SlideBottom>
                            
                        </div>
                        <div className="text-center md:text-left pt-[8px] lg:pt-[12px]  font-[400] pr-0 sm:pr-[20px] lg:pr-0 md:max-w-[350px] lg:max-w-[436px] leading-[28px]">
                            <SlideBottom delay={0.5}>
                                <MarkdownView markup={aboutPage?.mission?.description} />
                            </SlideBottom>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 pt-[20px] lg:pt-[35px] pl-0 lg:pl-[24px]">
                        <div className="inter text-center md:text-left text-[24px] md:text-[32px] font-[700] text-secondary">
                            <SlideBottom delay={0.2}>
                                <MarkdownView markup={aboutPage?.vision?.heading} />
                            </SlideBottom>
                        </div>
                        <div className="text-center md:text-left pt-[8px] lg:pt-[12px] font-[400]  pr-0 sm:pr-[20px] lg:pr-0   md:max-w-[350px] lg:max-w-[436px] leading-[28px]">
                            <SlideBottom delay={0.5}>
                            <MarkdownView markup={aboutPage?.vision?.description} />
                            </SlideBottom>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
