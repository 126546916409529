import React from 'react'
import MarkdownView from 'react-showdown';

import ButtonPrimary from 'components/Buttons/Primary'
import { SlideLeft, SlideRight, SlideBottom, SlideTop, JustAppear } from 'components/SlideAnimation'
import { getImage } from 'components/getImage';
import { useNavigate } from 'react-router-dom';



export default function ExcecutiveTeam(props) {

    const { homePage } = props
    const navigate = useNavigate()

    return (
        <div className='mx-auto container'>
            <div className="mt-[60px] lg:mt-[100px]">
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="w-full lg:fit pt-[25px]">
                        <SlideBottom>
                            <div className="text-center md:text-left text-[24px] lg:text-[32px] 3xl:text-[40px] text-secondary font-bold">
                                {homePage?.exexutive_team?.heading}
                            </div>
                        </SlideBottom>
                        <SlideBottom delay={0.3}>

                            <div className="text-left mt-[24px] text-[14px] leading-[24px] 3xl:leading-[35px] lg:text-[16px] 3xl:text-[18px] w-full max-w-[325px] lg:max-w-[420px] 3xl:max-w-[480px] lg:leading-[28px] ">
                                <MarkdownView markdown={homePage?.exexutive_team?.description} />
                            </div>
                        </SlideBottom>
                        <SlideLeft delay={0.5}>
                            <div className="mt-[24px] 3xl:mt-[30px] mb-[10px] lg:mb-0">
                                <ButtonPrimary 
                                    onClick={() => navigate("/about#executive-team")}
                                    label="Learn more" 
                                    className="w-full  md:max-w-[270px]  text-[20px] h-[46px] lg:h-[60px]" />
                            </div>
                        </SlideLeft>
                    </div>
                    <div className="w-full  md:w-fit ">
                        {/* {console.log(homePage?.excecutivePagePhoto)} */}
                        <SlideRight>

                            <div className=" pl-0 pb-0  w-full h-full sm:w-[350px] lg:w-[445px] lg:h-[428px]_  3xl:w-[545px] ">
                                <img src={getImage(homePage?.exexutive_team?.image?.url, "assets/team/executive.png")} className="rounded-xl  w-full lg:w-  h-full ml-auto  " alt="" />
                            </div>
                        </SlideRight>
                    </div>
                </div>
            </div>
            <div className="mb-[60px] lg:mb-[100px]"></div>

        </div>
    )
}
