import React, { useEffect } from 'react';
import { getImage } from 'components/getImage';
import { JustAppear } from 'components/SlideAnimation';

export const GallaryFull = (props) => {

    const { items = [], onClose = () => { }, current = 0 } = props;

    let gallaryItem = [...items] || []
    gallaryItem = gallaryItem.sort((a, b) => a.id === current ? -1 : 1)

    useEffect(() => {
        // console.log(gallaryItem)
        // gallaryItem = [...gallaryItem].unshift([...gallaryItem].splice(current, 1)[0]);
        console.log(gallaryItem)

    }, [])

    return (
        <div
            onClick={(e) => {

                onClose();
            }}
            className='fixed z-[550] top-0 left-0 w-full h-full backdrop-blur-sm bg-black/30 '>
            <JustAppear>

                <div className="">
                    <div className=" px-[10px] mx-auto  py-[0px]  ">
                        <div
                            // onClick={e => e.stopPropagation()}
                            className="  rounded-xl   w-full">
                            <div
                                onClick={() => onClose()}
                                className="fixed right-[10px] p-[10px] text-right w-fit ml-auto cursor-pointer bg-red-500 rounded-xl ">
                                <div>
                                    <svg width={30} height={30} fill="white" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" /></svg>
                                </div>
                            </div>
                            <div 
                               
                                className="h-screen  overflow-y-scroll px-[20px] pt-[50px] pb-[200px]">
                                    <div className={`grid  grid-cols-1  gap-[14px]   mx-auto `}>
                                        {gallaryItem?.map((item, index) => (
                                            <div    className='grow '>
                                                <img 
                                                     onClick={e => e.stopPropagation()}
                                                    src={getImage(item?.image?.data?.attributes?.url)}
                                                    alt={item?.image?.data?.attributes?.url}
                                                    className='w-fit sm:h-fit max-h-[90vh] mx-auto rounded-2xl bg-slate-200 min-h-[100px]  text-xs' />
                                            </div>
                                        ))}

                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </JustAppear>

        </div>
    );
};
