import React from 'react'
import MarkdownView from 'react-showdown'

import { getImage } from 'components/getImage'

export default function Hero(props) {
    
    const {pageData} = props

    const _style = {
        mainBg : {
            background : `url(${getImage(pageData?.background?.url, "assets/backgrounds/forInvestors.png")})`,
            backgroundSize : 'cover',
            backgroundPosition : 'center'
        }
    }


    return (
        <div style={_style?.mainBg} className="relative h-[180px] lg:h-[300px]" >
            <div className="absolute h-full w-full bg-[#1D337A4D] "></div>
            <div  className='absolute w-full h-full left-0 top-0 '>
                <div className="container mx-auto  h-full">
                <div className=" flex flex-col  h-full items-center justify-center text-white font-bold ">
                    <div className="inter font-[700] text-[32px] lg:text-[48px]">
                        <MarkdownView markdown={pageData?.heading} />
                    </div>
                </div>
                </div>
            </div>
            
        </div>
    )
}
