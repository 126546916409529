import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';

import {Helmet} from "react-helmet";

// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

// component
import Hero from './Hero'
import VisionMission from './Vision'
import ExcecutiveTeam from './ExecutiveTeam'
import Values from './Values'
import VisionDrivers from './VisionDrivers'
import Porfolio from './Porfolio'
import Board from './Board'

// import {aboutPage} from './data'
import useStoreData from 'store/storeData'

import { useScrollBehaviours } from 'components/useScrollBehaviours'

export default function Index(props) {

    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()

    const {storeData} = useStoreData()
    const aboutPage = storeData?.aboutPage || {}

    // console.log(storeData)

    useEffect(() => {
        if (location.hash.slice(1)) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {

            ScrollToTop()
        }
        // ScrollToTop()
    }, [location])


    return (
        <>
            <Helmet>
                <title> About - BANA – Business Angel Network of Armenia  </title>
                <meta
                    name="description"
                    content="BANA Angels is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies. In addition to capital, the members bring their expertise, experience and network to influence the success of the startups they invest in."
                />
            </Helmet>
            <NavBar />
            <Hero pageData={aboutPage}  />
            <VisionMission aboutPage={aboutPage}  />
            <Values aboutPage={aboutPage}  />
            <ExcecutiveTeam aboutPage={aboutPage}   />
            <VisionDrivers aboutPage={aboutPage}   />
            <Porfolio aboutPage={aboutPage}   />
            <Board aboutPage={aboutPage}   />
            <Footer />

        </>
    )
}