import React from 'react'
import MarkdownView from 'react-showdown';
import ButtonSecondary from 'components/Buttons/Secondary'


import useMobile from 'components/useMobile'
import { SlideBottom } from 'components/SlideAnimation';
import { getImage } from 'components/getImage';
import { useNavigate } from 'react-router-dom';

export default function JoinUs(props) {

    const { homePage } = props
    const { isMobile } = useMobile()
    const navigate = useNavigate()


    const _style = {
        mainBg: {
            background: `url(${getImage(homePage?.JoinUs?.image?.url, "assets/backgrounds/footer.png")}),  #27386C`,
            backgroundSize: 'cover',
            backgroundPosition: "center"
        }
    }

    return (<div className='bg-[#F8F8F7] '>
        {
            isMobile ? <div className=' mx-auto'>
                <div className="container mx-auto">
                    <div className='text-center text-black text-[20px] font-bold leading-[26px]  md:text-[32px] pb-[12px] lg:pb-[24px] md:leading-[44px] md:text-[64px] text-white md:leading-[74px]'>
                        <MarkdownView markdown={homePage?.JoinUs?.heading} />
                    </div>
                </div>
                <img src={getImage(homePage?.JoinUs?.image?.url, "assets/backgrounds/footer.png")} className="w-full" />
                <div className="pt-[12px] lg:pt-[24px] pb-[60px] container mx-auto">
                    <ButtonSecondary 
                        onClick={() => navigate("/for-investors#join-us")}
                        label={homePage?.JoinUs?.button} className="min-w-[250px] h-[46px]" />
                </div>
            </div>
                :
                <div>
                    <div style={_style?.mainBg}>

                        <div className="py-[150px] flex flex-col items-center justify-center"
                        >
                            <div className='text-center text-[32px] leading-[44px]  lg:text-[64px] text-white lg:leading-[74px] font-[700]'>
                                <SlideBottom>
                                    <MarkdownView markdown={homePage?.JoinUs?.heading} />
                                </SlideBottom>
                            </div>
                            <div className="pt-[24px]">
                                <SlideBottom delay={0.5}>
                                    <ButtonSecondary 
                                        onClick={() => navigate("/for-investors#join-us")}
                                        label={homePage?.JoinUs?.button} className="min-w-[250px] inter font-[700] text-[20px] py-[10px]" />
                                </SlideBottom>
                            </div>
                        </div>
                    </div>

                </div>
        }

    </div>
    )
}
