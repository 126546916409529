import React, {useEffect} from 'react'

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useScrollBehaviours } from 'components/useScrollBehaviours'

import {factoryPage} from './data'

// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

import Hero from './Hero'
import Content from './Content'
import Projects from './Projects'
import useStoreData from 'store/storeData'

import InitProjects from './initProjects';

export default function Index(props) {

    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()
    const { fetchdata, loading : projectLoading} = InitProjects()

    const {storeData} = useStoreData()
    const factoryPage = storeData?.factoryPage || {}
    const projectsList = storeData?.projectsList || {}

    useEffect(() => {
        if (location.hash.slice(1)) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {

            ScrollToTop()
        }
        // ScrollToTop()
    }, [location])

    useEffect(() => {
        if(!projectsList?.items){
            fetchdata()
        }
    },[])

    // console.log(storeData)


    return (
        <>
            <Helmet>
                <title> The Factory - BANA – Business Angel Network of Armenia  </title>
                <meta
                    name="description"
                    content="The Factory is an innovation hub - a working space & environment, created by BANA Angels and Formula VC with the aim of providing the right and relevant startup knowledge & skills."
                />
            </Helmet>
            <NavBar />
            <Hero  pageData={factoryPage}/>
            <Content  pageData={factoryPage}/>
            <Projects   pageData={factoryPage} projectsList={projectsList} loading={projectLoading}/>
            <Footer />
        </>
    )
}