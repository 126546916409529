export const footer = {
    logoURL: '/assets/logo/logo.svg',
    links: [
        {
            label: "About",
            url: "/about",
        },
        {
            label: "For Investors",
            url: "/for-investors",
        },
        {
            label: "For Startups",
            url: "/for-startups",
        },
        {
            label: "The Factory",
            url: "/the-factory",
        },
        {
            label: "Events",
            url: "/events",
        },
        {
            label: "News",
            url: "/news",
        },
    ],
    address: "65/5 Halabyan St, 0078, Yerevan",
    phone: "+374 55 45 89 82",
    email: "info@bana.am",
    social: {
        twitter: "https://twitter.com",
        youtube: "https://youtube.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com",
        instagram : "https://www.instagram.com/bana_angels/"
    },
    copyright: "Copyright © 2010-2022. All Rights Reserved"


}