import React from 'react'


import { SecondaryCard } from 'components/Cards'
import Primary from 'components/Buttons/Primary'
import FooterIcons from 'components/Footer/Socialcons'
import { getImage } from 'components/getImage'
import MarkdownView from 'react-showdown'


export default function Gallary(props) {

    const { pageData } = props

    let _whoCanApply  = pageData?.whoCanApply?.items || []
    let chunk = _whoCanApply.length/2

    return (
        <div className='container mx-auto'>
            <div className="max-w-[683px] mx-auto  pt-[40px]">
                {
                    pageData?.gallary?.items?.length > 0 &&
                    (<div className="grid grid-cols-1 md:grid-cols-2 gap-y-[20px] lg:gap-x-[16px] gap-x-[20px] ">
                        {
                            pageData?.gallary?.items?.map(item => {
                                return(<div key={item.id} className="relative rounded-xl overflow-hidden">
                                <div className="absolute left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div>
                                <img src={getImage(item.image?.url, "/assets/sample/projects.png")} alt="" className=' w-full' />
                            </div>)
                            })
                        }
                    </div>)
                }
                 <div className="font-[700] text-[16px]  pt-[40px]">
                    <MarkdownView markup={pageData?.startups?.heading} />
                </div>
                {
                    pageData?.startups?.items?.length > 0 &&
                    <div className="grid grid-cols-3 md:grid-cols-5 gap-y-[20px] lg:gap-x-[30px] gap-x-[20px] py-[60px] lg:py-[20px]">
                        {pageData?.startups?.items?.map(item => {
                            return (<div key={item.id} className="relative rounded-[8px] overflow-hidden">
                                <div className="absolute left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div>
                                <img src={getImage(item.image?.url, "/assets/sample/projects.png")} alt="" className=' w-full' />

                            </div>)
                        })}
                    </div>
                }
                 <div className="font-[700] text-[16px] md:text-[16px] lg:pt-[20px]">
                    <MarkdownView markup={pageData?.whoCanApply?.heading} />
                </div>
                {
                    pageData?.whoCanApply?.items?.length > 0 &&
                    <div className="flex flex-wrap justify-between lg:mt-[20px]">
                        <div className='md:pr-[30px]'>
                            {_whoCanApply?.slice(0, chunk)?.map(item => {
                                return (<div key={item.id} className="flex font-[600] text-[12px] leading-[24px] text-[#615CB5] ">
                                    <div className="mr-[5px]"> - </div>
                                    <div className=""> {item.item} </div>

                                </div>)
                            })}
                        </div>
                        <div>
                            {_whoCanApply?.slice(chunk, chunk + _whoCanApply.length)?.map(item => {
                                return (<div key={item.id} className="flex font-[600] text-[12px] leading-[24px] text-[#615CB5] ">
                                    <div className="mr-[5px]"> - </div>
                                    <div className=""> {item.item} </div>

                                </div>)
                            })}
                        </div>
                    </div>
                }

                <SecondaryCard className="py-[36px] lg:py-[20px] bg-blue-applynow mt-[40px]">
                    <div className="text-[16px] font-[500] inter">
                        {pageData?.gallary?.description}
                    </div>
                    <div className='pt-[20px]'>
                        <a href={pageData?.gallary?.website} target="_blank">
                            <Primary label="Go to website" className="mx-auto max-w-[188px]" />
                        </a>
                    </div>
                </SecondaryCard>
                <div className="mt-[60px] lg:mt-[40px]  relative w-full overflow-hidden  pt-[56.25%] rounded-2xl">
                    <iframe className='absolute w-full h-full top-0 left-0 bg-slate-300' src={pageData?.gallary?.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="pt-[20px] lg:pt-[40px] text-[16px]">
                    <MarkdownView markup={pageData?.footerDescription} />
                </div>
                <div className="pt-[20px]">
                    <FooterIcons />
                </div>
                <div className="pb-[60px]"></div>
            </div>
        </div>
    )
}
