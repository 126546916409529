import { useEffect, useContext } from 'react';
import './App.css';
import './input.css';


import { Helmet } from 'react-helmet';
import RenderRoutes from 'routes'

// store
import { StateProvider as WebsiteDataProviders } from 'store/websiteDataStore'



function App() {



  return (
    <div className="App">
       <Helmet>
            <title> BANA ANGELS  </title>
        </Helmet>
      <WebsiteDataProviders>
        <RenderRoutes/>
      </WebsiteDataProviders>
    </div>
  );
}

export default App;
