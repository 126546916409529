import React, {useState, useEffect, useCallback} from 'react'
import Primary from 'components/Buttons/Primary'
import {useParams, useSearchParams} from 'react-router-dom';

// Form
import * as yup from 'yup';
import axios from 'axios'
import Loading from 'components/Buttons/Loading';


export default function Form(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    const initialValues = {
        name: "",
        company: "",
        occupation: "",
        email: "",
        phone: "",
        interests: "",
    }

    const [value, setValue] = useState(initialValues)

    const inputFields = [
        {
            name: "name",
            label: "Full Name*"
        },
        {
            name: "company",
            label: "Company*"
        },
        {
            name: "occupation",
            label: "Occupation*"
        },
        {
            name: "email",
            label: "Email*"
        },
        {
            name: "phone",
            label: "Phone Number*"
        },
        {
            name: "interests",
            label: "Interest fields"
        },
    ]

    const Schema = yup.object({
        // interests: yup.string().required("Interest fields  is required "),
        occupation: yup.string().required("Occupation is a required field"),
        company: yup.string().required("Company name is a required field"),
        phone: yup.string().required("Phone number is a required field"),
        email: yup.string().required("Email is a required field").email("Invalid email"),
        name: yup.string().required("Name is a required field"),

    });

    const handeChange = (e) => {
        setError("")
        setValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const onSuccessClose = () => {
        setSuccess(false)
        setValue(initialValues)
        setLoading(false)
    }

    const onSubmit = async () => {
        setError("")
        setLoading(true)
        try {
            await Schema.validate({...value})
            const res = await axios.post(`${process.env.REACT_APP_URL}/api/email-carrier/for-investors`, value)
            console.log(res)
            setLoading(false)
            setSuccess(true)

        } catch (ex) {
            setError(ex?.errors || ex?.response?.data?.error?.message || "Something went wrong")
            setLoading(false)
        }
        setLoading(false)
    }


    return (
        <div className='container mx-auto pt-[50px]'>
            <div className="flex flex-wrap">
                {inputFields.map(item => {
                    return(<div className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px] mb-[20px] md:mr-[20px]'>
                        <div className='text-[12px] pb-2 font-[600] text-[#252529]'>
                            {item?.label}
                        </div>
                        <div>
                            <input
                                value={value[item.name]}
                                name={item.name}
                                onChange={handeChange}
                                className='w-full h-[50px] border rounded-xl px-3 text-[14px]'
                                // placeholder={item?.label}
                            />
                        </div>
                    </div>)
                })}
            </div>
            {
                loading ?
                    <Loading text="Uploading data..."/>
                    : <>
                        <div className='py-[10px] lg:pt-[20px]  text-[14px] text-red-500'>
                            {`${error}`}
                        </div>
                        <div className="pt-[6px]">

                            <Primary
                                onClick={onSubmit}
                                color="bg-[#1D337A]"
                                label="Submit"
                                className="inter text-[20px] font-[700] md:max-w-[319px]  h-[46px] lg:h-[60px]"/>
                        </div>
                    </>
            }
            {
                success && <div
                    className='fixed z-50 w-screen h-screen bg-black/50 top-0 left-0 flex flex-col items-center justify-center'>
                    <div className="bg-white rounded-2xl p-[20px] text-center min-w-[300px] ">
                        <div className="text-[18px] font-bold ">Successfully submitted</div>
                        {/* <div className="text-[14px] pt-[10px]">
                        We’ll contact you soon!
                        </div> */}
                        <Primary
                            onClick={onSuccessClose}
                            color="bg-[#1D337A]"
                            label="OK"
                            className="inter text-[20px] font-[700] md:max-w-[100px] mx-auto mt-[20px] "/>
                    </div>
                </div>
            }
        </div>
    )
}
