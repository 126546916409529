import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useMobile from 'components/useMobile'

import MarkdownView from 'react-showdown'

// import { SubHeading} from 'components/Headings'
import Secondary from 'components/Buttons/Secondary'
import Primary from 'components/Buttons/Primary'
import { getImage, truncate } from 'components/getImage'

import { MutatingDots } from 'react-loader-spinner'
import { formatDate } from 'components/time'


export default function Content(props) {

    const { pageData, newsList, loading } = props
    const { isMobile } = useMobile()

    const [seeMore, setSeeMore] = useState(false)
    let newsData = newsList?.items || []

    const navigate = useNavigate()


    // const isMobile = width <= 768;

    if (seeMore) {
        // newsData = [...newsData].slice(0,3)
    } else {
        newsData = [...newsData].slice(0, 3)
    }




    return (
        <div className='container mx-auto'>
            {
                loading && <div className='w-fit text-center mx-auto pt-[40px]'>
                    <MutatingDots
                        height="100"
                        width="100"
                        color="#595EA4"
                        secondaryColor='#7CC7DE'
                        radius='12.5'
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            }
            <div className="pt-[15px] lg:pt-[40px]">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-4  gap-x-[12px] gap-4 ">
                    {newsData?.map(item => {
                        return (
                            <div 
                                key={item.id} 
                                onClick={() => navigate(`/news/viewer/${item.id}`)}
                                className="flex flex-col justify-between cursor-pointer">
                                    
                                <div className="relative  rounded-2xl  overflow-hidden sm:h-[200px]">
                                    {/* <div className="absolute rounded-2xl left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div> */}
                                    {/* <div className="absolute h-full w-full bg-[#1D337A4D] rounded-2xl  "></div> */}
                                    <img src={getImage(item.thumbnail?.url, "assets/sample/projects.png")} alt="" className='blur-lg h-full min-h-[200px] w-full' />
                                    <img src={getImage(item.thumbnail?.url, "assets/sample/projects.png")} alt="" className='absolute left-0 top-0 w-full sm:h-fit' />
                                </div>
                                <div className="inter font-[600] pt-[10px] lg:pt-[14px] text-[12px] text-[#C4C4C4]">
                                    {/* {formatDate(item.updatedAt)} */}
                                    {item.date ? formatDate(item.date) : formatDate(item.createdAt)}
                                </div>
                                <div className="pt-[10px] font-[700] text-[20px] font-bold">
                                    {item.heading}
                                </div>
                                <div className=" pt-[10px] font-[400] text-[14px] lg:text-[12px] opacity-[0.6]">
                                    <MarkdownView markup={truncate(item.description, 90)} />
                                </div>
                                <div className="inter pt-[10px] font-[600] ">
                                    <Link to={`/news/viewer/${item.id}`}>
                                        <Secondary label="Learn more" className=" h-[40px]  " />
                                    </Link>
                                </div>
                            </div>)
                    })}
                </div>
            </div>
            {
                // ((isMobile && pageData?.items?.length > 3) || (!isMobile && pageData?.items?.length > 6)) &&
                ((newsList?.items?.length > 3)) &&
                <div className="pt-[60px]">
                    <Primary
                        onClick={() => setSeeMore(!seeMore)}
                        label={seeMore ? "See less" : "See more"}
                        className="mx-auto md:max-w-[316px] text-[16px] py-[11px] px-[71px]" />
                </div>
            }
            <div className="pb-[80px]"></div>
        </div>
    )
}
