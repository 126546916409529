import React from 'react'
import MarkdownView from 'react-showdown'
import TeamCard from 'components/TeamCard'

export default function VisionDrivers(props) {

    const { aboutPage = {} } = props

    return (
        <div className='bg-blue-vision'>
            <div className="py-[60px] container mx-auto  text-white">
                <div className="text-[24px] text-center lg:text-left lg:text-[32px]  font-bold">
                    <MarkdownView markup={aboutPage?.visionDrivers?.heading} />
                </div>
                <div className="pt-[50px] flex flex-wrap lg:flex-nowrap justify-center lg:justify-between">
                    {aboutPage?.visionDrivers?.items?.map((item, index) => {
                        return (
                            <div key={index} className="text-center lg:ml-0 lg:text-left  w-full pb-[15px] lg:py-0   lg:max-w-[260px] flex items-start lg:mb-0" >
                                <div className="flex mx-auto lg:ml-0 items-start max-w-[260px]  lg:max-w-[260px] ">

                                    <div className='pt-3 pr-[12px] w-fit'> <div className="rounded-full h-[9px] w-[9px] bg-[#AEAAD0]"></div></div>
                                    <div className="grow md:text-[14px] lg:text-[16px] font-[400] leading-[28px] ">
                                        <MarkdownView markup={item?.item} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}
