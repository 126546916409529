import React from 'react'

export default function Primary(props) {
    
    const {label = "Button", onClick = () => {}, className = ""} = props

    return (
        <div 
            onClick={onClick}
            className={`flex flex-col justify-center items-center inter rounded-[10px] bg-[#A0E3E8] text-[#27386C] p-2  text-center font-bold cursor-pointer ${className}`}>
            {label}
        </div>
    )
}
