import React from 'react'
import MarkdownView from 'react-showdown'
import { SecondaryCard } from 'components/Cards'
import { getImage } from 'components/getImage'

export default function JoinUs(props) {

    const { pageData } = props

    return (<>
        <div className='pt-[40px] container mx-auto leading-[28px] font-[400]'>
            <MarkdownView markup={pageData?.description} />
        </div>
        <div className="overflow-x-hidden pt-[60px] md:pt-[70px]">
            <div className="hidden lg:block mx-auto w-full relative">
                <div 
                    style={{
                        backgroundImage : `url(${getImage(pageData?.graph_desktop?.url, "/assets/graphs/desktop.svg")})`,
                        backgroundPosition : 'top center',
                        backgroundRepeat : 'no-repeat'
                    }}
                    className=" mx-auto overflow-hidden  lg:min-h-[200px] ">
                    <img src={getImage(pageData?.graph_desktop?.url, "/assets/graphs/desktop.svg")} alt="" className=' h-fit mx-auto invisible' />
                </div>
                {/* <div className="absolute h-[10px] w-full bg-[#C5BBF0] mt-[50px]"></div> */}
            </div>
            <div className="block lg:hidden mx-auto w-full relative">
                {/* <div className="absolute h-[10px] w-full bg-[#C5BBF0] mt-[50px]"></div> */}
                <img src={getImage(pageData?.graph_mobile?.url, "/assets/graphs/mobile.svg")} alt="" className='mx-auto' />
            </div>

        </div>
        <div className='container mx-auto pt-[60px]'>
            <SecondaryCard className="pt-[65px] pb-[65px] lg:pt-[20px] lg:pb-[50px]  px-[20px] bg-blue-applynow  ">
                <div className="text-[24px] lg:text-[20px] font-bold">
                    <MarkdownView markup={pageData?.joinUs?.heading} />
                </div>
                <div className="pt-[14px] lg:pt-[20px] font-[500] inter">
                    <MarkdownView markup={pageData?.joinUs?.description} />
                </div>
            </SecondaryCard>
        </div>
        </>
    )
}
