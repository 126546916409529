import React, { useEffect } from 'react'


// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

import Hero from './Hero'
import Content from './Content'
import Gallary from './Gallary'

// import {sydicatePage} from './data'
import { useScrollBehaviours } from 'components/useScrollBehaviours'
import useStoreData from 'store/storeData'

export default function Index(props) {

    const { ScrollToTop } = useScrollBehaviours()
    const { storeData } = useStoreData()
    const sydicatePage = storeData?.sydicatePage || {}

    useEffect(() => {
        ScrollToTop()
    }, [])
    return (
        <>
            <NavBar />
            <Hero pageData={sydicatePage} />
            <Content pageData={sydicatePage} />
            <Gallary pageData={sydicatePage} />
            <Footer />

        </>
    )
}
