import React, { useRef, useState } from 'react'

// swiper
import Swiper from 'react-id-swiper';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css/bundle';

import { ReactComponent as NextIcon } from 'assets/buttons/next.svg';
import Callout from 'assets/ellipse/callout.svg'

import useMobile from 'components/useMobile'
import Secondary from 'components/Buttons/Secondary'
import { getImage } from 'components/getImage';


export default function Testmonials(props) {

    const { homePage } = props
    const { isMobile } = useMobile(1024)


    let testmonialList = homePage?.testmonials?.testmonials || []
    const [imageIndex, setImageIndex] = useState(0)
    const [showMore, setShowmore] = useState(false)

    const swiperRef = useRef(null);
    SwiperCore.use([Autoplay, Navigation]);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 3,
        centeredSlides: true,
        slidesPerView: 3,
        loop: true,
        observer: true,
        spaceBetween: 70,
        on: {
            realIndexChange: (swiper) => setImageIndex(swiper.realIndex)
        },
        // autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false
        // },
        breakpoints: {
            550: {
                // slidesPerView: 3,
            }
        }

    }

    const _style = {
        callout: {
            background: `url(${Callout})`,
            backgroundSize: 'cover'
        }
    }

    const RenderCard = (item, index) => {
        return (
            <div key={item?.id} className=' relative  transition-transform '>
                <div className="mt-[30px] lg:mt-0  lg:h-[350px] testmonial-card  flex flex-col justify-center">
                    <div >
                        <div className="block lg:hidden text-center testimonails-heading font-bold  text-[20px]">
                            {item?.title}
                        </div>
                        <div className="p-3 shadow bg-white  rounded-xl text-center pb-[20px] relative" >
                            <div className="hidden lg:block testimonails-heading font-bold  text-[24px]">
                                {item?.title}
                            </div>
                            <div className="testimonails-description opacity-90 text-[12px] lg:text-[10px] font-[400] leading-[20px] lg:leading-[18px] text-[#18191F]">
                                {item?.description}
                            </div>
                            <div className="absolute -bottom-[19px] -translate-x-[12px] rotate-[180deg]  w-[26px] overflow-hidden inline-block  ">
                                <div class=" h-[20px] w-[20px]  border bg-white rotate-45 transform origin-bottom-left"></div>
                            </div>
                        </div>
                        <div className="pt-[24px] text-center">
                            <div className=" w-[58px] h-[58px] lg:w-[48px] lg:h-[48px] overflow-hidden rounded-full bg-sky-400 mx-auto">
                                <img src={getImage(item.photo?.url, "assets/team/demo.png")} alt="" className='w-full h-full' />
                            </div>
                            <div className="text-[16px] lg:text-[14px] font-bold">
                                {item?.name}
                            </div>
                            <div className="text-[14px] lg:text-[12px] opacity-[0.4] text-[#474A57] font-[400]">
                                {item?.company}
                            </div>
                        </div>
                    </div>
                    {/* {item?.name} */}
                </div>
            </div>
        )
    }

    if (isMobile && !showMore) {
        testmonialList = [...testmonialList].slice(0, 2)
    }



    return (<div>
        {isMobile ? <div>
            <div className="mobile grid grid-cols-1 ">
                {testmonialList.map(RenderCard)}

            </div>
            <div className="pt-[20px]">
                <Secondary
                    onClick={() => setShowmore(!showMore)}
                    label={showMore ? "See less" : "See more"}
                    className="w-full mx-auto max-w-[400px] h-[46px] text-[18px font-[600] inter"
                />
            </div>
        </div>
            :
            <div className="  ">
                <div className="center-card-testimonails" >
                    <div className="container mx-auto  w-full relative hidden sm:block   ">
                        <div className="absolute left-[40px] top-[100px]  -translate-x-[100px] rotate-180">  <NextIcon onClick={() => goPrev()} className="cursor-pointer" /> </div>
                        <div className="absolute right-[40px]  top-[100px]  translate-x-[100px]"> <NextIcon onClick={() => goNext()} className="cursor-pointer" /> </div>
                    </div>
                    <div className="overflow-hidden ">
                        {testmonialList.length > 0 &&
                            <Swiper {...params} ref={swiperRef}>
                                {testmonialList.map(RenderCard)}
                            </Swiper>
                        }
                    </div>

                </div>
            </div>
        }

    </div>
    )
}
