import React from 'react'
import MarkdownView from 'react-showdown'

import { SubHeading } from 'components/Headings'
import {RenderCardSmall} from 'components/TeamCard'


export default function Investors(props) {

    const { pageData } = props

    return (
        <div className='container mx-auto '>
            <div className="pt-[60px] leading-[28px]">
                <MarkdownView markup={pageData?.investors?.description} />
            </div>
            <div className="pt-[80px] text-center text-[32px] inter ">
                <SubHeading>{pageData?.investors?.heading} </SubHeading>
            </div>
            <div className="pt-[16px]">
                {/* <TeamCard teamList={pageData?.investors?.teamList} /> */}
                <div className="grid grid-cols-1  md:grid-cols-4  gap-[20px]">
                    {pageData?.investors?.teamList?.map(RenderCardSmall)}
                </div>
            </div>
        </div>
    )
}
