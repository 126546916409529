import React from 'react'

import TeamCard from 'components/TeamCard'
import { SubHeading } from 'components/Headings'

import { ReactComponent as PdfIcon } from 'assets/icons/pdf-icon.svg'
import { getImage } from 'components/getImage'

export default function ExecutiveTeam(props) {
    
    const { aboutPage } = props
    
    const _publications = aboutPage?.publications || []

    return (<>
        <div className='pt-[70px] pb-[20px] container mx-auto '>
            <SubHeading className="text-center  inter ">
                {aboutPage?.board?.heading}
            </SubHeading>
            <TeamCard teamList={aboutPage?.board?.teamList} />
        </div>
        <div className="py-[30px] container mx-auto">
            {_publications.length > 0 &&
            <div className="flex ">
                <div className="text-[18px] inter opacity-[0.6] font-[700] mr-[12px] ">
                    {/* {aboutPage?.publications?.heading} */}
                    Publications
                </div>
                <div className="flex ">
                    {_publications?.map(item => (<a key={item.id} href={getImage(item.pdf?.url, item?.link)} target="_blank" alt={item.name} className="mr-3">
                        <span > <PdfIcon className='w-[25px]' /> &nbsp;</span>
                    </a> ))}
                </div>
            </div>
            }
        </div>
        </>
    )
}
