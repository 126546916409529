import React from 'react'
import { Link } from 'react-router-dom'

export default function Index(props) {
    

    return (
        <>
            <div className="w-screen h-screen bg-[#595EA4] flex flex-col justify-center items-center">
                <div className="text-center">
                    <div className="text-[34px] sm:text-[60px] text-white font-bold">404</div>
                    <div className="text-[24px] sm:text-[20px] text-white ">Sorry! This link is broken : (</div>
                    <div className='my-[40px]'>
                        <Link to="/" className=' p-4 bg-white text-black rounded-xl'> Home </Link>
                    </div>
                </div> 
            </div>
        </>
    )
}
