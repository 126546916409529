import React, { useState, useEffect } from 'react'
import Primary from 'components/Buttons/Primary'

import { ReactComponent as Clip } from 'assets/buttons/clip.svg'

// Form
import * as yup from 'yup';
import axios from 'axios'
import Loading from 'components/Buttons/Loading';

export default function Form(props) {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        startup_name: "",
        startup_url: "",
        linkedin: "",
        elevator_pitch_url: "",
        team_size: "",

        is_previous_investments: "yes",
        investment_amount: "",
        extra_info: "",
    }

    const [value, setValue] = useState(initialValues)

    const [pitch_file, setPitch_file] = useState(undefined)

    const _supported_files = [
        "application/vnd.ms-powerpoint",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ]

    const Schema = yup.object({
        pitch_file: yup.mixed()
                    .required(' Pitch deck file is required')
                    .test( "fileSize", "Pitch deck file should be less than 15 MB.", (value) => value && value.size <= 1024 * 1024 * 15)
                    .test(
                        'fileFormat',
                        'Pitch deck file should be .pdf or .ppt',
                        (value) => value === null || (value && _supported_files.includes(value.type))
                      ),
        // team_size: yup.number().typeError('Team size should be a number').required("team size  is required field"),
        // elevator_pitch_url: yup.string().required("elevator pitch  is required field"),
        // linkedin: yup.string().required("Linkedin link  is required field"),
        // startup_url: yup.string().required("Startup url is required field"),
        team_size: yup.string().required("Team size  is required field").matches(/^\d+$/, "Team size should be a number"),
        startup_name: yup.string().required("Startup name is required field"),
        phone: yup.string().required("Phone number is required field"),
        email: yup.string().required("Email is a required field").email("Invalid email"),
        name: yup.string().required("Name is a required field"),

    });


    const inputFields = [
        {
            name: "name",
            label: "Applicant's full name*"
        },
        {
            name: "email",
            label: "Contact email*"
        },
        {
            name: "phone",
            label: "Phone number*"
        },
        {
            name: "startup_name",
            label: "Startup name*"
        },
        {
            name: "startup_url",
            label: "Startup URL"
        },
        {
            name: "linkedin",
            label: "Social media link"
        },
        {
            name: "elevator_pitch_url",
            label: "URL for your elevator pitch"
        },
        {
            name: "team_size",
            label: "Team size*"
        },
    ]

    const inputFields2 = [
        {
            name: "investment_amount",
            label: "If yes, how much was the investment ?"
        },
        {
            name: "extra_info",
            label: "Anything else we need to know?"
        },
    ]

    const handeChange = (e) => {
        setError("")
        setValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }


    const onFileChange = (e) => {
        // console.log("file")
        setError("")
        setPitch_file(e.target.files[0])
    }

    const onFileUpload = async () => {
        setError("")
        setLoading(true)
        
        try {

            await Schema.validate({...value, pitch_file : pitch_file})
            const formData = new FormData()
            Object.keys(value).map(key => formData.append(key, value[key]))
            formData.append('files', pitch_file, pitch_file?.name);
            
            const res = await axios.post(`${process.env.REACT_APP_URL}/api/email-carrier/for-startups`, formData)
            console.log(res)
            setLoading(false)
            setSuccess(true)

        } catch (ex) {
            // console.log(ex?.errors || ex?.response?.data?.error?.message ||"Something went wrong")
            setError(ex?.errors || ex?.response?.data?.error?.message ||"Something went wrong")
            setLoading(false)
        }
        setLoading(false)
    }

    const onSuccessClose = () => {
        setSuccess(false)
        setValue(initialValues)
        setLoading(false)
        setPitch_file(undefined)
    }

    // console.log(value)
    return (
        <div className='container mx-auto pt-[60px] lg:pt-[20px]'>
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-16 gap-y-4"> */}
            <div className="flex flex-wrap">
                {inputFields.map(item => {
                    return (<div className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px] mb-[20px] md:mr-[20px] '>
                        <div className='text-[12px] pb-2 font-[600] text-[#252529]'>
                            {item?.label} 
                        </div>
                        <div>
                            <input
                                value={value[item.name]}
                                name={item.name}
                                onChange={handeChange}
                                className='w-full h-[50px] border rounded-xl px-3 text-[14px]'
                                // placeholder={item?.label}
                                disabled={loading}
                            />
                        </div>
                    </div>)
                })}
            </div>
            <div className="py-3">
                <div className="text-[12px] font-semibold"> Attach your pitch deck (pdf, ppt) <span className='font-normal'> [ Maximum size: 15MB ] .  </span>  *</div>
                <div className="pt-[10px]">
                    <label htmlFor="file-upload">
                        <div className='flex items-center w-fit cursor-pointer'>
                            <div className="w-fit px-[20px] py-[5px] bg-white font-[400] flex items-center mr-[10px]">
                                <Clip className='mr-[10px]' />   Choose file
                            </div>
                            {pitch_file ? <div className='text-[#BDBDBD] text-[14px]'>
                                {`${pitch_file?.name}`}
                            </div> : <div className='text-[#BDBDBD] text-[14px]'>
                                No file choosen
                            </div>}
                        </div>
                    </label>
                    <input id="file-upload" type="file"
                        // value={value.pitch_file}
                        disabled={loading}
                        onChange={onFileChange}
                        className='hidden mt-[10px] text-[14px] file:mr-[10px] text-[#BDBDBD]  file:bg-white file:border-0 file:px-[20px] file:py-[6px]' />
                </div>
            </div>
            <div className="py-3">
                <div className="text-[12px] font-semibold"> Have you received any investment ?</div>
                <div className='flex w-[100px] justify-between pt-[10px] font-[600]' >
                    <div className='text-[12px] flex items-center mr-[30px]'>
                        <input
                            id="radio-yes"
                            type="radio"
                            value="yes"
                            name="is_previous_investments"
                            disabled={loading}
                            className="appearance-none rounded-full w-[17px] h-[17px] border-2 border-[#0F90D1] checked:bg-[#27386C]"
                            checked={value.is_previous_investments === "yes"}
                            onChange={handeChange}
                        />
                        <label htmlFor='radio-yes' className='ml-[8px]' >Yes</label>

                    </div>
                    <div className='text-[12px] flex items-center '>
                        <input
                            id="radio-no"
                            type="radio"
                            value="no"
                            name="is_previous_investments"
                            className="appearance-none rounded-full w-[17px] h-[17px] border-2 border-[#0F90D1] checked:bg-[#27386C]"
                            checked={value.is_previous_investments === "no"}
                            onChange={handeChange}
                            disabled={loading}

                        />
                        <label htmlFor='radio-no' className='ml-[8px]' >No</label>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap lg:mt-[10px] mt-0">
                {inputFields2.map(item => {
                    return (<div className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px]  md:mr-[20px]  mb-[10px]'>
                        <div className='text-[12px] pb-2 font-semibold'>
                            {item?.label}
                        </div>
                        <div>
                            <input
                                value={value[item.name]}
                                name={item.name}
                                onChange={handeChange}
                                disabled={loading}
                                className='w-full h-[50px] border rounded-xl px-3 text-[14px]'
                                // placeholder={item?.label}

                            />
                        </div>
                    </div>)
                })}
            </div>
            
            {
                loading ?
                    <Loading  text="Uploading data..."/>
                    :<>
                    <div className='py-[10px] lg:pt-[20px]  text-[14px] text-red-500'>
                            {`${error}`}
                    </div>
                    <div className="pt-[6px]">
                        
                        <Primary
                            onClick={onFileUpload}
                            color="bg-[#1D337A]"
                            label="Submit"
                            className="inter text-[20px] font-[700] md:max-w-[319px]  h-[46px] lg:h-[60px]" />
                    </div>
                    </>
            }
            {
                success && <div className='fixed z-50 w-screen h-screen bg-black/50 top-0 left-0 flex flex-col items-center justify-center'>
                    <div className="bg-white rounded-2xl p-[20px] text-center min-w-[300px] ">
                        <div className="text-[18px] font-bold ">Successfully submitted</div>
                        <div className="text-[14px] pt-[10px]">
                        We’ll assess your application and <br/> reply within a week
                        </div>
                        <Primary
                            onClick={onSuccessClose}
                            color="bg-[#1D337A]"
                            label="OK"
                            className="inter text-[20px] font-[700] md:max-w-[100px] mx-auto mt-[20px] " />
                    </div>
                </div>
            }

        </div>
    )
}
