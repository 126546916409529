import Primary from "../../components/Buttons/Primary";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import axios from "axios";


export const ReturnUrl = () => {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId');
    const [loading, setLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState(0);
    const navigate = useNavigate();


    const getOrderStatus = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_URL}/api/transaction/getOrderStatus/${orderId}`)

            setLoading(false)
            setOrderStatus(res.data.orderStatus)

        } catch (ex) {
            setLoading(false)
        }}

    useEffect(() => {
        getOrderStatus()
    }, [])

    const back = () => {
        navigate("/for-investors")
    };

    const renderOrderStatus = useMemo(() => {
        if (loading) {
            return (
                <div className="text-[18px] font-bold ">Loading ...</div>
            )
        } else if (+orderStatus === 2) {
            return (
            <div className="text-[18px] font-bold ">Your payment is successfully processed!</div>
            )
        } else {
            return (
            <div className="text-[18px] font-bold ">Something was wrong</div>
            )
        }
    }, [loading, orderStatus])

    return (
        <div
            className='fixed z-50 w-screen h-screen top-0 left-0 flex flex-col items-center justify-center'>
            {renderOrderStatus}
            <Primary
                onClick={back}
                color="bg-[#1D337A]"
                label="Go Back"
                className="inter text-[20px] font-[700] md:max-w-[100px] mx-auto mt-[20px] px-2"/>
        </div>
    )
}
