import React, { useState, useEffect } from 'react'
import MarkdownView from 'react-showdown'

// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'
import FooterIcons, { FooterShareIcons } from 'components/Footer/Socialcons'


import { SecondaryCard } from 'components/Cards'
import Primary from 'components/Buttons/Primary'


import { useParams } from 'react-router-dom'
import { getImage } from 'components/getImage'

import { MutatingDots } from 'react-loader-spinner'
import { useScrollBehaviours } from 'components/useScrollBehaviours'

// import {FacebookShareButton} from 'react-share'

import { Helmet } from "react-helmet";
import { GallaryFull } from './GallaryFull'

const qs = require('qs');

export default function Index(props) {

    // const { aboutPage } = props

    const { id, type } = useParams()
    const { ScrollToTop } = useScrollBehaviours()

    const [newsData, setNewsData] = useState({})
    const [loading, setloading] = useState(true)
    const [popup, setPopup] = useState(-1)

    let gallaryImages = newsData?.gallary?.items.slice(0, 4) || []
    // gallaryImages

    const query = qs.stringify({
        populate: {
            cover_image: { fields: ["url"] },
            gallary: {
                populate: {
                    items: {
                        populate: {
                            image: { fields: ["url"] }
                        }
                    }
                }
            }
        }
    })

    // fetch data using id
    const fetchData = async () => {
        let url = ""
        if (type === "news") {
            url = `${process.env.REACT_APP_URL}/api/news-blogs/${id}?${query}`
        } else if (type === "event") {
            url = `${process.env.REACT_APP_URL}/api/event-blogs/${id}?${query}`

        }
        try {
            setloading(true)
            const res = await fetch(`${url}`)
            const data = await res.json()
            setNewsData(data?.data?.attributes)
            setloading(false)
        } catch (ex) {
            console.log(ex)
            // setloading(false)
        }
    }

    useEffect(() => {
        fetchData()
        ScrollToTop()
    }, [])

    const _style = {
        mainBg: {
            backgroundImage: `url(${getImage(newsData?.cover_image?.data?.attributes?.url, "/assets/backgrounds/about.png")})`,
            // backgroundImage : 'url(assets/backgrounds/about.png)',
            backgroundColor: "#1D337A",
            backgroundSize: 'cover',
            backgroundPosition: 'top',

        }
    }

    return (
        <>
            <Helmet>
                <title> {type === "news" ? "News" : "Events"} - Bana - {`${newsData?.heading ? newsData?.heading : ""}`} </title>
                <base target="_blank"></base>
            </Helmet>
            <NavBar />
            <div style={_style?.mainBg} className="bg-scroll md:bg-fixed" >
                <div className=' h-[180px] lg:h-[300px] relative backdrop-blur-[3px] '>
                    <div className="absolute h-full w-full bg-[#1D337A4D] "></div>
                    <div className=" absolute w-full h-full left-0 top-0 ">

                        <div className="container mx-auto  flex flex-col items-center h-full justify-center text-white font-bold">
                            <div className="text-[32px] lg:text-[48px]">
                                {loading ? <div className='w-fit text-center'>
                                    <MutatingDots
                                        height="100"
                                        width="100"
                                        color="#595EA4"
                                        secondaryColor='#7CC7DE'
                                        radius='12.5'
                                        ariaLabel="mutating-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                    <div className="text-[16px] pt-[0px]">
                                        Loading...
                                    </div>
                                </div>
                                    :
                                    <MarkdownView markdown={newsData?.heading} className="ext-ellipsis " />}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="container mx-auto pt-[30px] min-h-[300px] leading-[26px] ">
                <div className=" max-w-[800px] mx-auto">
                    {newsData?.content &&
                        <MarkdownView markdown={newsData?.content} className=" max-w-[800px] mx-auto" />
                    }
                    {
                        newsData?.gallary?.items?.length > 0 &&
                        <div className="pt-[10px] ">
                            <div className={`grid  grid-cols-1 ${newsData?.gallary?.items?.length > 1 ? "sm:grid-cols-2" : "sm:grid-cols-1"}  gap-[14px]   max-w-[800px] mx-auto `}>
                                {
                                    gallaryImages?.map((item, index) => (
                                        <div key={item.id} className='grow relative rounded-xl overflow-hidden'>
                                            <img src={getImage(item?.image?.data?.attributes?.url)}
                                                
                                                alt={item?.image?.data?.attributes?.url}
                                                className='w-full h-full blur-xl cursor-pointer rounded-2xl bg-slate-200 min-h-[100px]  text-xs' />
                                                <img
                                                    onClick={() => { setPopup(item.id) }} 
                                                    alt={item?.image?.data?.attributes?.url}
                                                    src={getImage(item?.image?.data?.attributes?.url)} className="cursor-pointer  w-full sm:h-fit absolute left-0 top-0" />
                                        </div>
                                    ))
                                }

                            </div>
                            {
                                newsData?.gallary?.items?.length > 4 &&
                                <div>
                                    <div
                                        onClick={() => { setPopup(0) }}
                                        className="w-fit mx-auto pt-[20px] font-bold text-primary underline cursor-pointer">
                                        View all images
                                    </div>
                                </div>
                            }
                            {popup >= 0 && <GallaryFull 
                                current={popup}
                                onClose={() => setPopup(-1)}
                                items={newsData?.gallary?.items} />
                            }

                        </div>
                    }
                    {newsData?.content_2 &&
                        <MarkdownView markdown={newsData?.content_2} className="mt-[20px]" />
                    }
                    {newsData?.gallary &&
                        <div className="max-w-[683px] mx-auto mt-[40px]">

                            {
                                newsData?.gallary?.website &&
                                <SecondaryCard className="py-[36px] lg:py-[20px] bg-blue-applynow">
                                    <div className="text-[16px] font-[500] inter">
                                        {newsData?.gallary?.description}
                                    </div>
                                    <div className='pt-[20px]'>
                                        <a href={newsData?.gallary?.website} target="_blank">
                                            <Primary label="Go to website" className="mx-auto max-w-[188px]" />
                                        </a>
                                    </div>
                                </SecondaryCard>
                            }
                            {
                                newsData?.gallary?.video &&
                                <div className="mt-[60px] lg:mt-[40px]   relative w-full overflow-hidden  pt-[56.25%] rounded-2xl">
                                    <iframe className='absolute w-full h-full top-0 left-0 bg-slate-300' src={newsData?.gallary?.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            }

                        </div>
                    }
                    {newsData?.heading &&
                        <div className="py-[20px]  max-w-[800px] mx-auto">
                            <FooterShareIcons
                                url={window.location.href}
                                // url={"https://bana.am/about/"}
                                heading={newsData?.heading}

                            />
                        </div>
                    }
                    {/* {newsData?.heading &&
                        <div className="py-[20px]  max-w-[800px] mx-auto">
                            
                            <FooterIcons />
                        </div>
                    } */}
                </div>

            </div>
            <Footer />
        </>
    )
}


