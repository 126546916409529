import React, {useEffect} from 'react'

import { useLocation } from 'react-router-dom';
import { useScrollBehaviours } from 'components/useScrollBehaviours'

import { SlideLeft, SlideRight, SlideBottom, JustAppear} from 'components/SlideAnimation'
import { motion } from "framer-motion"

// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

import Content from './content'
import Numbers from './Numbers'
import NumbersBlue from './NumbersBlue'
import Founders from './Founders'
import Syndicate from './Syndicate'
import Testmonials from './Testmonials'
import JoinUs from './JoinUs'

import CoInvested from './CoInvested'
import MailList from './MailList'
import ExcecutiveTeam from './ExecutiveTeam'
import { Helmet } from 'react-helmet';

// data
// import { homePage} from './data'
import useStoreData from 'store/storeData'


export default function Index(props) {

    // const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()
    const {storeData} = useStoreData()
    const homePage = storeData?.homePage || {}

    useEffect(() => {
        ScrollToTop()
    }, [])
    // console.log(storeData)

    const _image =  homePage?.hero_background?.url ? `${process.env.REACT_APP_URL}${homePage?.hero_background?.url}` : "assets/backgrounds/hero.png"


    return (
        <>
         <Helmet>
            {/*<title> BANA – Business Angel Network of Armenia  </title>*/}
            <meta
                 name="description"
                 content="We provide a platform for investors to meet and cooperate with their peers, and connect them with promising startups and entrepreneurs."
                 />
        </Helmet>
        <div style={{
             backgroundImage : `url(${_image})`,
             backgroundSize : 'cover'
        }} className={`h-screen bg-no-repeat bg-center overflow-y-hidden min-h-[600px] max-h-[700px] sm:max-h-screen md:h-[600px] lg:h-[700px] xl:h-[881px]  `}>
            <div className="relative w-full">
                <div className="absolute  left-0 top-0 w-full z-30">
                    <NavBar transparent={true} />
                </div>

                <div className="absolute w-full z-20 left-0 top-0">
                    <div className="container mx-auto">
                            {/* Bg circles */}
                            {/* ----------------- */}
                            <div className="relative z-[30]">
                            <SlideRight initial={{ opacity: 0.3, x: -100 }} whileInView={{ opacity: 0.9 }} className="hidden sm:block absolute z-[12] w-[785px] h-[785px] bg-[#595EA4] opacity-90 rounded-full -left-[725px]  -top-[246px]"></SlideRight>
                            <SlideRight initial={{ opacity: 0.3 }} whileInView={{ opacity: 0.9 }} className="hidden sm:block absolute z-[10] w-[785px] h-[785px] bg-[#7CC7DE] opacity-90 rounded-full -left-[580px]  top-[244px]"></SlideRight>
                            <SlideBottom delay={0.6} initial={{ opacity: 0.3, y: -50 }} whileInView={{ opacity: 1 }} className="hidden sm:block absolute z-[11] w-[411px] h-[411px] bg-[#194081] rounded-full  -left-[510px]  top-[460px]"></SlideBottom>
                            </div>

                            {/* ----------------- */}
                            <div className="relative z-[40]" >
                            <Content homePage={homePage} />
                            </div>
                        {/* Home */}
                    </div>
                </div>
            </div>
        </div>
        <Numbers homePage={homePage} />
        <NumbersBlue homePage={homePage} />

            <Testmonials homePage={homePage} />
        <ExcecutiveTeam homePage={homePage} />
        <Syndicate homePage={homePage} />
        <div className='bg-[#F8F8F7]'>
            <div className=" mx-auto">

            <Founders homePage={homePage} />
            <div className="pb-[60px] lg:pb-[100px]"></div>
            <CoInvested homePage={homePage} />
            <div className="pb-[60px] lg:pb-[90px]"></div>
            <MailList homePage={homePage} />
            <div className="pb-[60px] lg:pb-[100px]"></div>
            </div>
        </div>
        <JoinUs homePage={homePage} />
        <Footer />
        </>
    )
}



{/* <SlideRight initial={{ opacity: 0.3, x: -100 }} whileInView={{ opacity: 0.9 }} className="hidden sm:block absolute z-[12] w-[785px] h-[785px] bg-[#595EA4] opacity-90 rounded-full -left-[400px] sm:-left-[640px] md:-left-[700px] lg:-left-[560px] xl:-left-[472px] -top-[246px]"></SlideRight>
<SlideRight initial={{ opacity: 0.3 }} whileInView={{ opacity: 0.9 }} className="hidden sm:block absolute z-[10] w-[785px] h-[785px] bg-[#7CC7DE] opacity-90 rounded-full -left-[400px] sm:-left-[480px] md:-left-[540px] lg:-left-[440px]  xl:-left-[343px] top-[244px]"></SlideRight>
<SlideBottom delay={0.6} initial={{ opacity: 0.3, y: -50 }} whileInView={{ opacity: 1 }} className="hidden sm:block absolute z-[11] w-[411px] h-[411px] bg-[#194081] rounded-full  -left-[350px] sm:-left-[340px]  lg:-left-[300px] xl:-left-[250px] top-[460px]"></SlideBottom> */}
