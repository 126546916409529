import Loading from "../../components/Buttons/Loading";
import Primary from "../../components/Buttons/Primary";
import React, {useCallback, useState} from "react";
import axios from "axios";
import {SecondaryCard} from "../../components/Cards";
import MarkdownView from "react-showdown";

export const InvestForm = () => {

    const [error, setError] = useState({
        error: false,
        errorMessage: null
    })
    const [investLoading, setInvestLoading] = useState(false)
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        amount: '',
        language: 'en'
    })

    const handleFullNameChange = useCallback((e) => {
        setFormData({...formData, fullName: e.target.value})
    }, [formData.fullName, formData.email, formData.amount])

    const handleEmailChange = useCallback((e) => {
        setFormData({...formData, email: e.target.value})
    }, [formData.fullName, formData.email, formData.amount])

    const handleAmountChange = useCallback((e) => {
        setFormData({...formData, amount: e.target.value})
    }, [formData.fullName, formData.email, formData.amount])

    const pay = async () => {

        if (!formData.fullName.length || !formData.email.length || !formData.amount.length) {
            setError({error: true, errorMessage: 'Please fill correct data'})
            return
        }
        setError({error: false, errorMessage: null})
        setInvestLoading(true)

        try {

            const res = await axios.post(`${process.env.REACT_APP_URL}/api/transaction/invest`, formData)
            if (res.data.error) {
                setError({error: res.data.error, errorMessage: res.data.errorMessage})
            }else {
                window.location.href = res.data.url
            }

            setInvestLoading(false)

        } catch (ex) {
            setInvestLoading(false)
        }
        setInvestLoading(false)
    }

    return (
        <>
            <div className='container mx-auto pt-[60px]'>
                <SecondaryCard className="pt-[65px] pb-[65px] lg:pt-[50px] lg:pb-[50px]  px-[20px] bg-blue-applynow  ">
                    <div className="text-[24px] lg:text-[20px] font-bold">
                        <MarkdownView markup={'Membership Fee Payment'}/>
                    </div>
                </SecondaryCard>
            </div>

            <div className='container mx-auto'>
                <div className="pt-[80px]"></div>
                <div className="flex flex-wrap">
                    <div className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px] mb-[20px] md:mr-[20px]'>
                        <div className='text-[12px] pb-2 font-[600] text-[#252529]'>
                            Full Name*
                        </div>
                        <div>
                            <input
                                value={formData.fullName}
                                onChange={handleFullNameChange}
                                className='w-full h-[50px] border rounded-xl px-3 text-[14px]'
                            />
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px] mb-[20px] md:mr-[20px]'>
                        <div className='text-[12px] pb-2 font-[600] text-[#252529]'>
                            Email*
                        </div>
                        <div>
                            <input
                                value={formData.email}
                                onChange={handleEmailChange}
                                className='w-full h-[50px] border rounded-xl px-3 text-[14px]'
                            />
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px] mb-[20px] md:mr-[20px]'>
                        <div className='text-[12px] pb-2 font-[600] text-[#252529]'>
                            Amount* &#1423;
                        </div>
                        <div>
                            <input
                                type='number'
                                value={formData.amount}
                                onChange={handleAmountChange}
                                className='w-full h-[50px] border rounded-xl px-3 text-[14px] appearance-none'
                            />
                        </div>
                    </div>
                    {
                        investLoading &&
                        <Loading text="Uploading data..."/>
                    }
                    <div
                        className='w-full lg:w-1/2 lg:max-w-[420px]  3xl:max-w-[560px] mb-[20px] md:mr-[20px] flex items-end'>
                        <div className='w-full'>
                            <Primary
                                onClick={pay}
                                color="bg-[#1D337A]"
                                label="Pay"
                                className="inter text-[20px] font-[700] w-full h-[50px] border rounded-xl px-3 text-[14px]"
                            />
                        </div>
                    </div>
                </div>
                {error.error &&
                <div className="py-[10px] lg:pt-[20px]  text-[14px] text-red-500">
                    {error.errorMessage}
                </div>
                }
                <div className="pb-[80px]"></div>

            </div>
        </>
    )
}
