import IconStar from 'assets/icons/star.svg'
import IconDiamond from 'assets/icons/diamond.svg'
import IconbarGraph from 'assets/icons/barGraph.svg'
import Iconnotepad from 'assets/icons/notepad.svg'
import Iconsuitcase from 'assets/icons/suitcase.svg'

export const _icons = {
    star : IconStar,
    diamond  : IconDiamond,
    barGraph : IconbarGraph,
    notepad : Iconnotepad,
    suitcase : Iconsuitcase,
}

  
export const aboutPage = {
    bgMainURL : 'assets/backgrounds/about.png',
    heading : "About BANA",
    mission : {
        heading : "Our mission",
        description : "Connect leading angel investors from around the globe with talented Armenian entrepreneurs."
    },
    vision : {
        heading : "Big Vision",
        description : "To have developed angel investing culture in Armenia that brings greater value to the world."

    },
    values : {
        heading : "Our values",
        items : [
            {
                icon : "star",
                iconURL : "",
                value : "Leadership"
            },
            {
                icon : "notepad",
                iconURL : "",
                value : "Integrity"
            },
            {
                icon : "suitcase",
                iconURL : "",
                value : "Collaboration"
            },
            {
                icon : "barGraph",
                iconURL : "",
                value : "Result Driven"
            },
            {
                icon : "diamond",
                iconURL : "",
                value : "Impact"
            },
        ]
    },
    executiveTeam : {
        heading : "Executive team",
        teamList : [
            {
                id :1,
                name : "Name surname",
                position : "CEO and Founder of Exper",
                details : "Business Angel Network of Armenia (BANA) is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies.",
                linkedin : "https://linkedin.com",
                photo : 'assets/team/demo.png',
            },
            {
                id :2,
                name : "Name surname2",
                position : "CEO and Founder of Exper",
                details : "Business Angel Network of Armenia (BANA) is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies.",
                linkedin : "https://linkedin.com",
                photo : 'assets/team/demo.png'
            },
            {
                id :3,
                name : "Name surname3",
                position : "CEO and Founder of Exper",
                details : "Business Angel Network of Armenia (BANA) is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies.",
                linkedin : "https://linkedin.com",
                photo : 'assets/team/demo.png'
            }, 
            {
                id :4,
                name : "Name surname3",
                position : "CEO and Founder of Exper",
                details : "Business Angel Network of Armenia (BANA) is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies.",
                linkedin : "https://linkedin.com",
                photo : 'assets/team/demo.png'
            }, 
            {
                id :5,
                name : "Name surname3",
                position : "CEO and Founder of Exper",
                details : "Business Angel Network of Armenia (BANA) is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies.",
                linkedin : "https://linkedin.com",
                photo : 'assets/team/demo.png'
            }, 
            {
                id :6,
                name : "Name surname3",
                position : "CEO and Founder of Exper",
                details : "Business Angel Network of Armenia (BANA) is a network of investors, entrepreneurs and executives from Armenia and abroad interested in making investments in startup companies.",
                linkedin : "https://linkedin.com",
                photo : 'assets/team/demo.png'
            }, 
        ]

    },
    visionDrivers : {
        heading : "Vision Drivers",
        items : [
            {
                item : "Talented Armenian geniuses & entrepreneurs",
            },
            {
                item : "Robust & globally competitive Investment scene",
                
            },
            {
                item : "Links & bridges everywhere inside & outside Armenia"

            }
        ]
    },
    portfolio : {
        heading : "Portfolio",
        items : [
            {
                item : "assets/sample/company.png"
            },
            {
                item : "assets/sample/company.png"
            },
            {
                item : "assets/sample/company.png"
            },
            {
                item : "assets/sample/company.png"
            },
            {
                item : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
            {
                logo : "assets/sample/company.png"
            },
        ]
    },
    board : {
        heading : "Board",
        teamList : [
            {
                id :1,
                name : "Name surname",
                position : "CEO and Founder of Exper",
                photo : 'assets/team/demo.png',
            },
            {
                id :2,
                name : "Name surname2",
                position : "CEO and Founder of Exper",
                photo : 'assets/team/demo.png'
            },
            {
                id :3,
                name : "Name surname3",
                position : "CEO and Founder of Exper",
                photo : 'assets/team/demo.png'
            }, 
        ]

    },

   
}

