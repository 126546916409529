import React, { useState, useEffect } from 'react'
import MarkdownView from 'react-showdown'


import { Helmet } from 'react-helmet';
// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'
import FooterIcons, { FooterShareIcons } from 'components/Footer/Socialcons'

import { SecondaryCard } from 'components/Cards'
import Primary from 'components/Buttons/Primary'


import { useParams } from 'react-router-dom'
import { getImage } from 'components/getImage'
import { ReactComponent as CheckBox } from 'assets/logo/checkBox.svg'


import { MutatingDots } from 'react-loader-spinner'
import { useScrollBehaviours } from 'components/useScrollBehaviours'
import { GallaryFull } from './GallaryFull'


const qs = require('qs');

export default function Index(props) {

    // const { aboutPage } = props

    const { id, type } = useParams()
    const { ScrollToTop } = useScrollBehaviours()

    const [newsData, setNewsData] = useState({})
    const [loading, setloading] = useState(true)
    const [popup, setPopup] = useState(-1)

    let gallaryImages = newsData?.gallary?.items.slice(0, 4) || []




    const query = qs.stringify({
        populate: {

            logo: { fields: ["url"] },
            social : true,
            background: { fields: ["url"] },
            check_lists: {
                populate: ["items"]
            },
            bullet_point_lists: {
                populate: ["items"]
            },
            brand_logo_list: {
                populate: {
                    items: {
                        populate: {
                            logo: { fields: ["url"] }
                        }
                    }
                }
            },
            gallary: {
                populate: {
                    items: {
                        populate: {
                            image: { fields: ["url"] }
                        }
                    }
                }
            }
        }


    });

    // fetch data using id
    const fetchData = async () => {
        let url = `${process.env.REACT_APP_URL}/api/projects/${id}?${query}`

        try {
            setloading(true)
            const res = await fetch(`${url}`)
            const data = await res.json()
            setNewsData(data?.data?.attributes)
            setloading(false)
        } catch (ex) {
            console.log(ex)
            // setloading(false)
        }
    }

    useEffect(() => {
        fetchData()
        ScrollToTop()
    }, [])
    const _style = {
        mainBg: {
            backgroundImage: `url(${getImage(newsData?.background?.data?.attributes?.url, "")})`,
            // backgroundImage : 'url(assets/backgrounds/about.png)',
            backgroundColor: "#1D337A",
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }


    const BulletPointBlock = (props) => {

        const {bullet_point_lists  = {}} = props
        let _bulletpoints =bullet_point_lists?.items || []
        let chunk = _bulletpoints.length / 2

        return (<div className='pb-[20px]'>
            {
               bullet_point_lists?.heading &&

                <div className="font-[700] text-[16px] md:text-[16px] lg:pt-[20px]">
                    <MarkdownView markup={bullet_point_lists?.heading} />
                </div>
            }
            {
               bullet_point_lists?.items?.length > 0 &&
                <div className="flex flex-wrap justify-between lg:mt-[20px]">
                    <div className='sm:pr-[30px]'>
                        {_bulletpoints?.slice(0, chunk)?.map(item => {
                            return (<div key={item.id} className="flex font-[600] text-[12px] leading-[24px] text-[#615CB5] ">
                                <div className="mr-[5px]"> - </div>
                                <div className=""> {item.item} </div>

                            </div>)
                        })}
                    </div>
                    <div>
                        {_bulletpoints?.slice(chunk, chunk + (_bulletpoints.length))?.map(item => {
                            return (<div key={item.id} className="flex font-[600] text-[12px] leading-[24px] text-[#615CB5] ">
                                <div className="mr-[5px]"> - </div>
                                <div className=""> {item.item} </div>

                            </div>)
                        })}
                    </div>
                </div>
            }
        </div>)
    }

    return (
        <>
            <Helmet>
                <title> Projects - BANA – {`${newsData?.heading ? newsData?.heading : ""}`}  </title>
                <base target="_blank"></base>
            </Helmet>
            <NavBar />
            <div style={_style?.mainBg} >
                <div className=' h-[180px] lg:h-[300px] relative'>
                    <div className="absolute h-full w-full bg-[#1D337A4D] "></div>
                    <div className=" absolute w-full h-full left-0 top-0 ">

                        <div className="container mx-auto  flex flex-col items-center h-full justify-center text-white font-bold">
                            <div className="text-[32px] lg:text-[48px]">
                                {loading ?
                                    <div className='w-fit text-center'>
                                        <MutatingDots
                                            height="100"
                                            width="100"
                                            color="#595EA4"
                                            secondaryColor='#7CC7DE'
                                            radius='12.5'
                                            ariaLabel="mutating-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                        <div className="text-[16px] pt-[0px]">
                                            Loading...
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {/* {console.log(newsData?.data?.attributes?.url)} */}
                                        {newsData?.logo?.data?.attributes?.url ?
                                            <img src={getImage(newsData?.logo?.data?.attributes?.url, "")} className="mx-auto w-[230px] sm:w-[400px] md:w-fit" alt="Logo" />
                                            :
                                            <MarkdownView markdown={newsData?.heading} className="ext-ellipsis " />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="container mx-auto pt-[30px] min-h-[300px] leading-[26px] ">
                <div className="max-w-[685px] mx-auto">
                    {newsData?.description &&
                        <MarkdownView markdown={newsData?.description} className="max-w-[685px] mx-auto" />
                    }
                    <div className="pt-[30px]"></div>
                    {
                        newsData?.check_lists?.map(checkList => {
                            return (<div  key={checkList.id} className="pb-[20px]">
                                <div className="font-[700] text-[16px] md:text-[24px]">
                                    <MarkdownView markup={checkList?.heading} />
                                </div>
                                <div className="">
                                    {checkList?.items?.map(item => {
                                        return (<div key={item.id} className="inter pt-[20px] font-[400] lg:text-[14px] md:text-[16px]">
                                            <div className="flex">
                                                <div className='mr-[20px]'>
                                                    <CheckBox />
                                                </div>
                                                <MarkdownView markup={item.item} className="max-w-[580px] " />
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>)
                        })
                    }
                    {newsData?.description_middle &&
                        <MarkdownView markdown={newsData?.description_middle} className="max-w-[685px] mx-auto pt-[40px]" />
                    }
                    {
                        newsData?.gallary?.items?.length > 0 &&
                        <div className="pt-[40px] ">
                            <div className={`grid  grid-cols-1 ${newsData?.gallary?.items?.length >= 1 ? "sm:grid-cols-2" : "sm:grid-cols-1"}  gap-[14px]  max-w-[685px] mx-auto `}>

                                {
                                    gallaryImages?.map((item, index) => (
                                        <div key={item.id} className='grow relative rounded-xl overflow-hidden'>
                                        <img src={getImage(item?.image?.data?.attributes?.url)}
                                            
                                            alt={item?.image?.data?.attributes?.url}
                                            className='w-full h-full blur-xl cursor-pointer rounded-2xl bg-slate-200 min-h-[100px]  text-xs' />
                                            <img
                                                onClick={() => { setPopup(item.id) }} 
                                                alt={item?.image?.data?.attributes?.url}
                                                src={getImage(item?.image?.data?.attributes?.url)} className="cursor-pointer  w-full sm:h-fit absolute left-0 top-0" />
                                    </div>
                                    ))
                                }
                            </div>
                            {
                                newsData?.gallary?.items?.length > 4 &&
                                <div>
                                    <div
                                        onClick={() => { setPopup(0) }}
                                        className="w-fit mx-auto pt-[20px] font-bold text-primary underline cursor-pointer">
                                        View all images
                                    </div>
                                </div>
                            }
                            {popup >= 0 && <GallaryFull 
                                current={popup}
                                onClose={() => setPopup(-1)}
                                items={newsData?.gallary?.items} />
                            }

                        </div>
                    }
                    {
                        newsData?.brand_logo_list?.heading &&

                        <div className="font-[700] text-[16px]  pt-[40px]">
                            <MarkdownView markup={newsData?.brand_logo_list?.heading} />
                        </div>
                    }
                    {
                        newsData?.brand_logo_list?.items?.length > 0 &&
                        <div className="grid grid-cols-3 md:grid-cols-5 gap-y-[20px] lg:gap-x-[30px] gap-x-[20px] py-[60px] sm:py-[20px]">
                            {newsData?.brand_logo_list?.items?.map(item => {
                                return (<div key={item.id} className="relative rounded-[8px] overflow-hidden">
                                    {/* <div className="absolute left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div> */}
                                    <img src={getImage(item.image?.url, "/assets/sample/projects.png")} alt="" className=' w-full' />

                                </div>)
                            })}
                        </div>
                    }
                    {/* {console.log( newsData?.bullet_point_lists)} */}
                    {
                        newsData?.bullet_point_lists?.length > 0 &&   newsData?.bullet_point_lists?.map((bullet_point_lists) => {
                            return (<div className='' key={bullet_point_lists.id}>
                                <BulletPointBlock bullet_point_lists={bullet_point_lists} />
                            </div>)})
                    }
                    


                    {newsData?.gallary &&
                        <div className="max-w-[683px] mx-auto mt-[40px]">

                            {
                                newsData?.gallary?.website &&
                                <SecondaryCard className="py-[36px] lg:py-[20px] bg-blue-applynow">
                                    <div className="text-[16px] font-[500] inter">
                                        {newsData?.gallary?.description}
                                    </div>
                                    <div className='pt-[20px]'>
                                        <a href={newsData?.gallary?.website} target="_blank">
                                            <Primary label="Go to website" className="mx-auto max-w-[188px]" />
                                        </a>
                                    </div>
                                </SecondaryCard>
                            }
                            {
                                newsData?.gallary?.video &&
                                <div className="mt-[60px] lg:mt-[40px]   relative w-full overflow-hidden  pt-[56.25%] rounded-2xl">
                                    <iframe className='absolute w-full h-full top-0 left-0 bg-slate-300' src={newsData?.gallary?.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            }
                            

                        </div>
                    }
                    {newsData?.description_footer &&
                                <MarkdownView markdown={newsData?.description_footer} className="max-w-[685px] mx-auto pt-[40px]" />
                            }
                    {!loading &&
                        <div className="pt-[20px]">
                            <FooterIcons social={newsData?.social || {}} />
                            {/* <FooterShareIcons
                                url={window.location.href}
                                // url={"https://bana.am/about/"}
                                heading={newsData?.heading}

                            /> */}
                        </div>
                    }
                    <div className="pb-[60px]"></div>
                </div>

            </div>
            <Footer />
        </>
    )
}
