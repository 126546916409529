import React, {useEffect, useState} from 'react'
import { SubHeading } from 'components/Headings'
import Secondary from 'components/Buttons/Secondary'

import useMobile from 'components/useMobile'
import { getImage } from 'components/getImage'


export default function Porfolio(props) {

    const { aboutPage } = props
    const {isMobile} = useMobile()
    

    let portfolioList = aboutPage?.protfolio?.items || []
    const [seeMore, setSeeMore] = useState(false)

    const goToWebsite = (_link) => {
        if(!_link){
            return
        }
        const newWindow = window.open(_link, '_blank', 'noopener,noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }

    // const isMobile = width <= 768;

    if(isMobile && !seeMore){
        portfolioList = [...portfolioList].slice(0,6)
    } else if(!seeMore) {
        portfolioList = [...portfolioList].slice(0,12)
    }



    return (
        <div className='pt-[70px] container mx-auto'>
            <SubHeading className="text-center inter">
                {aboutPage?.protfolio?.heading}
            </SubHeading>
            <div className="pt-[18px] lg:pt-[20px]  grid grid-cols-3 md:grid-cols-6 gap-[24px]">
                {portfolioList?.map((item, index) => {
                    return (<div key={index} 
                        onClick={()=> goToWebsite(item.website)}
                        className='  max-w-[200px] min-h-[120px] rounded-[8px] overflow-hidden flex flex-cols justify-center bg-white px-[10px]  items-center grayscale-[80%] hover:scale-[1.1] hover:grayscale-0   transition cursor-pointer'>
                        <img src={getImage(item?.logo?.url, "assets/sample/company.png")} alt="bana portfolio" className='w-fit max-h-fit mx-auto  ' />
                    </div>)
                })}
            </div>
            <div className="pt-[21px]">
                <Secondary 
                    onClick={() => setSeeMore(!seeMore)} 
                    label={seeMore ? "See less" : "See more"} 
                    className="mx-auto md:max-w-[216px] text-[16px] py-[11px] px-[71px]" />
            </div>
        </div>
    )
}
