import { JustAppear, SlideBottom } from 'components/SlideAnimation'
import React from 'react'
import MarkdownView from 'react-showdown'
import {_icons} from './data'

export default function Values(props) {

    const {aboutPage} = props

    return (
        <div className='container mx-auto'>
            <div className="pt-[80px] lg:pt-[70px]">
                <div className="inter text-[24px] md:text-[32px] font-[700] text-secondary text-center"> 
                    <SlideBottom delay={0.8}>
                        <MarkdownView markup={aboutPage?.values?.heading} />
                    </SlideBottom>
                </div>
                <div className="pt-[15px] lg:pt-[20px]  ">
                    <div className="rounded-xl bg-[#e4e7f1]  py-[34px] px-[52px] w-full flex  flex-wrap justify-between md:flex-nowrap md:justify-between">
                        {aboutPage?.values?.items?.map((item, index) => {
                            return(<JustAppear delay={index * 0.2} className={`text-center  ${((index + 1)%3 === 0)&& "w-full md:w-fit"} ${(index >= 2 && "mt-[30px] sm:mt-0")}`} key={index}>
                                <img src={item?.icon ? _icons[item?.icon] : item?.iconURL } alt="" className='mx-auto ' />
                                <div className="pt-[12px] font-[700] text-[14px] md:text-[16px] lg:text-[24px]">
                                    {item?.value}
                                </div>
                            </JustAppear>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
