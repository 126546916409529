import React from 'react'
import MarkdownView from 'react-showdown'

import { SubHeading } from 'components/Headings'
import { Link, useNavigate } from 'react-router-dom'
import { getImage, truncate } from 'components/getImage'

import { MutatingDots } from 'react-loader-spinner'


export default function Projects(props) {

    const { pageData, loading, projectsList = {} } = props

    const navigate = useNavigate()

    const _currentProjects = pageData?.current_projects || []
    const _pastProjects = projectsList?.items?.filter(i => !_currentProjects.filter(y => y.id === i.id).length) || [];

    // console.log(_pastProjects, _currentProjects)

    return (
        <div id='projects' className='container mx-auto'>
            <div className="inter font-bold pt-[60px] md:pt-[40px] text-center md:text-left">
                <SubHeading>
                    <MarkdownView markup={pageData?.current_project_heading} />
                </SubHeading>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[20px] lg:gap-y-4 gap-x-[12px] pt-[12px] lg:pt-[19px]">
                {_currentProjects?.map(item => {
                    return (<div key={item.id} >
                        <Link to={`/the-factory/project/${item.id}`} className="text-black">
                            <div className='rounded-2xl max-h-[250px] overflow-hidden relative h-[70%]'>

                                <img src={getImage(item.thumbnail?.url, "/assets/sample/projects.png")} alt={item.thumbnail?.url} className='blur-xl w-full min-h-[180px] sm:min-h-[230px]  h-full bg-slate-100' />
                                <img src={getImage(item.thumbnail?.url, "/assets/sample/projects.png")} alt={item.thumbnail?.url} className='absolute let-0 top-0 w-full sm:h-fit' />
                            </div>
                            <div className="pt-[10px] lg:pt-[20px] text-[20px] lg:text-[24px] font-bold">
                                {item.heading}
                            </div>
                            <div className="pt-[10px] text-[14px] max-w-[] opacity-[0.6] ">
                                <MarkdownView markup={item.sub_heading} className="text-ellipsis lg:max-w-[439px]" />
                            </div>
                        </Link>
                    </div>)
                })}
            </div>
            {
                loading && <div className='w-fit text-center mx-auto pt-[40px]'>
                    <MutatingDots
                        height="100"
                        width="100"
                        color="#595EA4"
                        secondaryColor='#7CC7DE'
                        radius='12.5'
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            }
            {_pastProjects.length > 0 && <>
                <div id="pastprojects" className="inter pt-[60px] md:pt-[40px]  text-center md:text-left">
                    <SubHeading>
                        <MarkdownView markup={pageData?.past_project_heading} />
                    </SubHeading>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 gap-x-[40px] pt-[60px] lg:pt-[20px] ">
                    {_pastProjects.map(item => {
                        return (
                        <div onClick={() => navigate(`/the-factory/project/${item.id}`)} key={item.id} className="cursor-pointer rounded-lg md:rounded-xl overflow-hidden ">
                                <div className="cursor-pointer rounded-lg md:rounded-xl overflow-hidden relative h-[60%]">
                                    <img src={getImage(item.thumbnail?.url, "assets/sample/projects.png")} className='blur-xl h-full w-full min-h-[100px]  text-[12px]' alt={item.thumbnail?.url} />
                                    <img src={getImage(item.thumbnail?.url, "assets/sample/projects.png")} className='absolute left-0 top-0 w-full sm:h-fit' alt={item.thumbnail?.url} />
                                </div>
                            <div className="pt-[10px] lg:pt-[20px] text-[16px] lg:text-[18px] font-bold">
                                {item.heading}
                            </div>
                            <div className="pt-[10px] text-[12px] max-w-[] opacity-[0.6] ">
                                <MarkdownView markup={truncate(`${item.sub_heading || ""}`, 60)} className="text-ellipsis lg:max-w-[439px]" />
                            </div>
                        </div>)
                    })}
                </div>
            </>}
            <div className="pb-[60px] lg:pb-[40px]"></div>
        </div>
    )
}
