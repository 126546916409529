import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Layout
import NavBar from 'components/Navbar'
import Footer from 'components/Footer'

// pages
import Hero from './Hero'
import JoinUs from './JoinUs'
import Form from './Form'
// import Investors from './Investors'

// import {forInvestorsPage} from './data'
import useStoreData from 'store/storeData'

import { useScrollBehaviours } from 'components/useScrollBehaviours'


export default function Index(props) {

    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()
    const { storeData } = useStoreData()
    const forStartupsPage = storeData?.forStartupsPage || {}


    useEffect(() => {
        if (location.hash.slice(1)) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {

            ScrollToTop()
        }
        // ScrollToTop()
    }, [location])


    return (
        <>
        <Helmet>
            <title> For startups - BANA ANGELS – Business Angel Network of Armenia  </title>
            <meta
                name="description"
                content="BANA Angels invests in Armenian startups with high-growth potential that already have initial traction, or MVP. Our team and partners individually work with teams on their business development plans and prepare them for investment. "
            />
        </Helmet>
        <NavBar  />
        <Hero pageData={forStartupsPage} />
        <JoinUs pageData={forStartupsPage} />
        <Form   />
        {/* <Investors pageData={forInvestorsPage} />  */}
        <div className="mb-[60px]"></div>
        <Footer />
        </>
    )
}
