import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import useMobile from 'components/useMobile'

import MarkdownView from 'react-showdown'

import { SubHeading } from 'components/Headings'
import Secondary from 'components/Buttons/Secondary'
import Primary from 'components/Buttons/Primary'

import { getImage, truncate } from 'components/getImage'
import { formatDate } from 'components/time'

import { MutatingDots } from 'react-loader-spinner'


export default function Content(props) {

    const { pageData, eventList = {}, loading } = props

    const { isMobile } = useMobile()
    const navigate = useNavigate()
    const [seeMore, setSeeMore] = useState(false)

    const _upcomingEvents = pageData?.upcoming_event || []
    // const _pastEvents = pageData?.upcoming_event?.filter(item => item.event_type === "past_event" && item.publishedAt !== null) || []

    const _pastEvents = eventList?.items?.filter(i => !_upcomingEvents.filter(y => y.id === i.id).length) || [];
    let _pastEvents_items = [..._pastEvents]


    if (seeMore) {
        // _pastEvents = [..._pastEvents].slice(0,3)
    } else {
        _pastEvents_items = [..._pastEvents].slice(0, 3)
    }

    const redirectRoute = (id) => {
        navigate(`/event/viewer/${id}`)
    }

    // console.log(_upcomingEvents)
    const ComingEventCard = ({ item }) => {
        return (
            <div key={item.id} className="h-full">
                <div onClick={() => redirectRoute(item.id)} className="cursor-pointer flex flex-col justify-between">
                    <div className="relative">
                        {/* <div className="absolute rounded-2xl left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div> */}
                        <div className="absolute  z-[5] flex flex-col items-center justify-center h-[48px] left-0 bottom-0  bg-[#C5BBF0] text-[#252529] uppercase text-[24px] font-[700] px-[30px] ">
                            Upcoming
                        </div>
                        <div className="relative overflow-hidden rounded-2xl ">

                            <img
                                alt={item.thumbnail?.url}
                                src={getImage(item.thumbnail?.url, "assets/sample/projects.png")}
                                className=' w-full  h-fit blur-xl  max-h-[450px] bg-slate-100 min-h-[200px] rounded-[20px] text-xs ' />
                            <img
                                alt={item.thumbnail?.url}
                                src={getImage(item.thumbnail?.url, "assets/sample/projects.png")}
                                className='absolute w-full sm:h-fit left-0 top-0 z-[4]' />
                        </div>
                        {/* <div className="w-full h-full overflow-hidden ">
                    </div> */}
                    </div>
                    <div className="pt-[30px] text-[14px] text-[#C4C4C4] font-[600]">
                        {/* {formatDate(item.updatedAt)} */}
                        {item.date ? formatDate(item.date) : formatDate(item.updatedAt)}
                    </div>
                    <div className="pt-[6px] text-[24px] font-bold">
                        {item.heading}
                    </div>
                    <div className="pt-[6px] text-[14px] max-w-[414px]">
                        <MarkdownView markup={truncate(item.sub_heading, 90)} />
                    </div>
                </div>
                <div className="pt-[6px]">
                    <a href={item.link} target="_blank">
                        <Secondary label="Register now" className=" md:max-w-[300px] font-[600]" />
                    </a>
                </div>
            </div>
        )
    }


    return (
        <div className='container mx-auto'>
            {_upcomingEvents.length > 0 && <>
                <div className="pt-[15px] lg:pt-[40px]">
                    <SubHeading>
                        <MarkdownView markup={pageData?.heading_1} className="text-[32px] inter font-[700]" />
                    </SubHeading>
                </div>
                {/* {console.log(_upcomingEvents.length)} */}
                <div className="">
                    <div className={`grid grid-cols-1 ${_upcomingEvents?.length <= 1 ? "md:grid-cols-1" : "md:grid-cols-2"}  gap-4 gap-x-[10px] pt-[10px] `}>
                        {_upcomingEvents?.map(item => {
                            return (<ComingEventCard key={item.id} item={item} />)
                        })}
                    </div>
                </div>
            </>
            }
            {
                loading && <div className='w-fit text-center mx-auto pt-[40px]'>
                    <MutatingDots
                        height="100"
                        width="100"
                        color="#595EA4"
                        secondaryColor='#7CC7DE'
                        radius='12.5'
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            }
            {_pastEvents.length > 0 && <>
                <div className="pt-[60px] font-[700]">
                    <SubHeading>
                        <MarkdownView markup={pageData?.heading_2} />
                    </SubHeading>
                </div>

                <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-x-[13px] pt-[20px]">
                        {_pastEvents_items?.map(item => {
                            return (
                            <div key={item.id}
                                onClick={() => navigate(`/event/viewer/${item.id}`)}
                                className="h-full flex flex-col justify-between cursor-pointer">
                                <div className="relative overflow-hidden rounded-2xl sm:h-[200px]">
                                    {/* <div className="absolute rounded-2xl left-0 top-0 h-full w-full bg-[#1D337A] opacity-30"> </div> */}
                                    <img src={getImage(item.thumbnail?.url, "assets/sample/projects.png")} alt={item.thumbnail?.url} className='blur-xl rounded-2xl w-full h-full w-full min-h-[100px]' />
                                    <img src={getImage(item.thumbnail?.url, "assets/sample/projects.png")} alt={item.thumbnail?.url} className='absolute left-0 top-0  rounded-2xl w-full w-full sm:h-fit min-h-[200px]' />
                                </div>
                                <div className="pt-[20px] text-[12px] text-[#C4C4C4] font-[600]">
                                    {/* {formatDate(item.updatedAt)} */}
                                    {item.date ? formatDate(item.date) : formatDate(item.createdAt)}
                                </div>
                                <div className="pt-[6px] text-[20px] font-bold">
                                    {item.heading}
                                </div>
                                <div className="pt-[6px] text-[12px] min-h-[40px] ">
                                    <MarkdownView markup={truncate((item.sub_heading || ""), 90)} />
                                </div>
                                <div className="pt-[6px]">
                                    {/* <Link to={`/viewer/event/${item.id}`}> */}
                                        <Secondary label="Learn more" className="md:max-w-[400px]" />
                                    {/* </Link> */}
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
                {
                    // ((isMobile && _pastEvents.length > 3) || (!isMobile && _pastEvents.length > 6)) &&
                    ((_pastEvents.length > 3)) &&
                    <div className="pt-[60px]">
                        <Primary
                            onClick={() => setSeeMore(!seeMore)}
                            label={seeMore ? "See less" : "See more"}
                            className="mx-auto md:max-w-[316px] text-[16px] py-[11px] px-[71px]" />
                    </div>
                }
            </>
            }
            <div className="pb-[80px]"></div>
        </div>
    )
}
